import React, { createContext } from "react";
import timezoneDetermine from "../../utils/timezone-determine";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { AppContext } from "../../context/app-context";
import simpleCallBackend from "../../utils/api-client";
import useLocalStorageState from "use-local-storage-state";
import { useSnackbar } from "notistack";

const TiresContext = createContext();

const TiresProvider = (props) => {
  //Запрос прайс-листа собственных товаров
  const contextAuth = React.useContext(AuthContext);
  const { setCartInfoRefetchCounter } = React.useContext(AppContext);

  const { enqueueSnackbar } = useSnackbar();
  const timezone = timezoneDetermine();
  const location = useLocation();
  const [update, setUpdate] = React.useState(true);
  const [loadingPriceList, setLoadingPriceList] = React.useState(false);
  const [priceList, setPriceList] = React.useState([]);
  const [dataRecieved, setDataRecieved] = React.useState(false);
  const [activeType, setActiveType] = useLocalStorageState("activeTiresType", {
    defaultValue: 0,
  });
  const [parameterList, setParameterList] = React.useState([]);
  const [itemToAddToBasket, setItemToAddToBasket] = React.useState({});
  const [machineType, setMachineType] = React.useState("Грузовые");
  const [currentSkuInfo, setCurrentSkuInfo] = React.useState([]);
  const [clearBtnClicked, setClearBtnClicked] = React.useState(false);
  const [selectedFilterListTires, setSelectedFilterListTires] =
    useLocalStorageState("selectedFilterListTires", { defaultValue: [] });
  const [
    selectedFilterListTiresLastUpdate,
    setSelectedFilterListTiresLastUpdate,
  ] = useLocalStorageState("selectedFilterListTiresLastUpdate", {
    defaultValue: null,
  });

  React.useEffect(()=>{
    handShowBtnClicked()
  },[selectedFilterListTires])

  const handleChangeFilterList = (
    property,
    property_type,
    property_value_id
  ) => {
    let index = selectedFilterListTires.findIndex((el) => el.name === property);
    let array = selectedFilterListTires[index]?.value
      ? selectedFilterListTires[index]?.value
      : [];

    let valueIndex = array.findIndex((el) => el === property_value_id);
    if (valueIndex === -1) {
      if (property_type === "select") {
        array = [];
      }
      if (property_value_id !== null) {
        array.push(property_value_id);
      }
    } else {
      if (property_type === "list" || property_type === "brand_list")
        array.splice(valueIndex, 1);
    }
    let list = JSON.parse(JSON.stringify(selectedFilterListTires));

    if (index !== -1) {
      list[index].value = array;
      setSelectedFilterListTires(JSON.parse(JSON.stringify(list)));
    }
  };

  const handleChangeMachineType = (value) => {
    if (machineType !== value) {
      setMachineType(value);
    }
  };

  React.useEffect(() => {
    if (selectedFilterListTires.map((el) => el.value).flat(1).length === 0) {
      setUpdate(true);
    }
  }, [selectedFilterListTires]);

  React.useEffect(() => {
    if (clearBtnClicked) {
      let tmp = JSON.parse(JSON.stringify(selectedFilterListTires));
      tmp.forEach((el) => (el.value = []));
      setSelectedFilterListTires(JSON.parse(JSON.stringify(tmp)));
      setClearBtnClicked(false);
    }
  }, [clearBtnClicked]);

  const handleClearFilterList = () => {
    setClearBtnClicked(true);
  };

  const handleBtnInfo = (skuId) => {
    let objectType = 'Грузовые шины'
    if (machineType === 'Спецтехника'){
      objectType = 'Спецтехника шины'
    }
    const body = { sku_id: skuId, object_type: objectType, do_not_delete_user_id_from_arg: true };
    const res = simpleCallBackend(
      contextAuth,
      "/data/app_ecomn_get_wheels_and_tires_sku_info",
      timezone,
      "POST",
      body
    ).then((data) => {
      setCurrentSkuInfo(data.data);
    });
    return res;
  };

  const handShowBtnClicked = () => {
    setUpdate(true);
  };

  React.useEffect(()=>{
    if(activeType ===0){
      setMachineType("Грузовые")
    }else{
      setMachineType("Спецтехника")
    }
  },[activeType])

  React.useEffect(() => {
    setUpdate(true);
  }, [machineType]);

  React.useEffect(() => {
    if (
      priceList.length === 0 &&
      dataRecieved &&
      parameterList.length !== 0 &&
      selectedFilterListTires.map((el) => el.value).flat(1).length !== 0
    ) {
      enqueueSnackbar(
        "Товаров удовлетворяющим условиям фильтрации не найдено!",
        {
          variant: "warning",
          autoHideDuration: 3000,
        }
      );
    }
  }, [priceList, selectedFilterListTires]);

  React.useEffect(() => {
    const featureIdArray = selectedFilterListTires
      .filter((el) => el.name !== "brand")
      .map((el) => el.value)
      .flat(1);
    const brandIdArray = selectedFilterListTires
      .filter((el) => el.name === "brand")
      .map((el) => el.value)
      .flat(1);
    const featureIdObject = featureIdArray.map((el) => {
      return { feature_id: el };
    });
    const brandIdObject = brandIdArray.map((el) => {
      return { brand_id: el };
    });
    if (location?.pathname === "/tires" && update) {
      handleSendFilterList(featureIdObject, brandIdObject);
    }
  }, [update]);

  //Добавление в корзину
  React.useEffect(() => {
    if ("sku_id" in itemToAddToBasket || "item_id" in itemToAddToBasket) {
      simpleCallBackend(
        contextAuth,
        "/data/app_ecomn_add_to_cart_wheels_and_tires",
        timezone,
        "POST",
        {
          action: "ADD",
          cart_id: null,
          is_partner_sku: itemToAddToBasket?.is_partner_sku,
          sku_id: itemToAddToBasket?.sku_id,
          item_id: itemToAddToBasket?.item_id,
          partner_search_log_id: itemToAddToBasket?.partner_search_log_id,
          quantity: itemToAddToBasket?.quantity,
          warehouse_id: itemToAddToBasket?.warehouse_id,
          do_not_delete_user_id_from_arg: true,
          delivery_time: itemToAddToBasket?.delivery_time,
          machine_type: activeType === 0 ? "Грузовые":"Спецтехника", 
          sku_kind: "Шины",
        }
      ).then((data) => {
        setCartInfoRefetchCounter((counter) => counter + 1);
        if (data?.is_ok)
          enqueueSnackbar(
            "Товар " +
              itemToAddToBasket?.pin +
              " " +
              itemToAddToBasket?.brand +
              " в количестве " +
              itemToAddToBasket?.quantity +
              " шт успешно добавлен в корзину",
            {
              variant: "success",
              autoHideDuration: 5000,
            }
          );
        else
          enqueueSnackbar(
            "Возникла ошибка при добавлении товара в корзину: " + data?.message,
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
      });
    }
  }, [itemToAddToBasket]);

  React.useEffect(()=>{    
    if (dataRecieved) {      
      const currentDate = new Date();
      simpleCallBackend(
        contextAuth,
        "/data/app_ecomn_get_parameters_list_for_tires_v2",
        timezone,
        "POST",
        {
          machine_type: machineType,
          do_not_delete_user_id_from_arg: true,
          sku_list: priceList.map((el) => {
            return { sku_id: el.sku_id };
          }),

        }
      )
        .then((data) => {
          if (Array.isArray(data?.data)) {
            setParameterList(data.data);
            let tmp = data.data.map((el) => {
              return { name: el.name, value: [] };
            });
            if (selectedFilterListTires.length === 0) {
              setSelectedFilterListTires(tmp);
              setSelectedFilterListTiresLastUpdate(currentDate);
            }
          } else setParameterList([]);
        })
        .catch(() => {
          setParameterList([]);
        });
    }
  },[dataRecieved])

  const handleSendFilterList = (
    featureListObject = [],
    brandListObject = []
  ) => {
    setDataRecieved(false);
    setLoadingPriceList(true);
    const lastUpdate = new Date(selectedFilterListTiresLastUpdate);
    console.log(lastUpdate)
    const body = {
      do_not_delete_user_id_from_arg: true,
      filter_list: featureListObject,
      brand_list: brandListObject,
      machine_type: activeType === 0 ? "Грузовые":"Спецтехника", 
      sku_kind: "Шины",
    };
    simpleCallBackend(
      contextAuth,
      "/data/app_ecomn_get_company_price_list_wheels_and_tires",
      timezone,
      "POST",
      body
    )
      .then((data) => {
        if (Array.isArray(data?.data)) setPriceList(data.data);
        else setPriceList([]);
      })
      .catch(() => {
        setPriceList([]);
      }).finally(()=>{
        setDataRecieved(true);
        setLoadingPriceList(false);
      })
    setUpdate(false);
  };

  return (
    <TiresContext.Provider
      value={{
        loadingPriceList,
        priceList,
        activeType,
        setActiveType,
        parameterList,
        selectedFilterListTires,
        handleChangeFilterList,
        handleClearFilterList,
        clearBtnClicked,
        handShowBtnClicked,
        setItemToAddToBasket,
        handleChangeMachineType,
        handleBtnInfo,
        currentSkuInfo,
      }}
      {...props}
    />
  );
};

export { TiresProvider, TiresContext };
