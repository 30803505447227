import React, { useMemo, useContext } from 'react'
import Table from '../../../common/table'
import { Box, Container, Typography } from '@mui/material';
import { ApplicationsContext } from '../../../applications/context/applications-context';




function Contractors() {
    const { clientInfo } = useContext(ApplicationsContext)


    const columns = useMemo(
        () => [
            {
                accesor: 'contractor',
                Header: 'Контрагент',
                Cell: ({ row }) => <span><b>{row.original.contractor}</b></span>
            },
            {
                accesor: 'inn',
                Header: 'ИНН',
                Cell: ({ row }) => <span><b>{row.original.inn}</b></span>
            },
        ],
        [clientInfo],
    );


    const tableStyles = {
        height: window.innerHeight - 380,
        rowHeight: 36,
        fontSize: 14,
        headerBgDark: '#616161',
        headerBgLight: '#d0d2d2',
        paddingFirstLastCell: '8px',
        outerBorder: true,
        rowExpandedHeight: 300,
        borderColor: '#d7d7d7',
    };

    return (
        <Container fixed maxWidth='xl'
            style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <Box paddingBottom={1}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>Контрагент</Typography>
            </Box>

            <Table
                columns={columns}
                data={clientInfo}
                styles={tableStyles}
            />
        </Container>
    )
}

export default Contractors
