import timezoneDetermine from '../timezone-determine'
import simpleCallBackend from '../api-client'

export const getActivityFromLocalStorage = () => {
    const data = localStorage.getItem('activity_user');

    if (data != null) {
        const arrData = JSON.parse(data);
        return arrData;
    }
    return [];
}

const getPath = (event) => {
    /// Если нет метода получения пути в событиях(если это мозила или сафари)
    if (!("path" in Event.prototype)){
        let path = [];
        let element = event.target;
        while (element) {
            path.push(element);
            element = element.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
        path.push(document);
        if (path.indexOf(window) === -1)
        path.push(window);
        return path.slice();
    } else {
        return event.path.slice();
    }
} 


const recEventInLocalStorage = (e, userContext, authContext) => {
    const timezone = timezoneDetermine()

    let elements = getPath(e);
    /// Отслеживаемый элемент.
    let trackingElement = null;
    const userInfo = userContext.userInfo;
    const typeActivity = e.type;
    let activity_name = null;
    const data = getActivityFromLocalStorage(activity_name)
    let title_activity = ''
    let description = ''


    // ///Удаление последних 5ти элементов пути(тех родителей, которые у всех элементов одинаковые)
    // elements.splice(elements.length - 5);


    /// Проход по дереву от вызванного компонента до самого родительского компонента с целью поиска аттрибута 'activity_name'
    for (let i = 0; i < elements.length; i++) {
        /// Проверка существования функции
        if (typeof elements[i].getAttribute !== 'undefined') {
            if (elements[i].getAttribute('activity_name') != null) {
                trackingElement = elements[i];
                activity_name = trackingElement.getAttribute('activity_name');
                description = trackingElement.getAttribute('description_activity');
                break;
            }
        }
    }


    switch (activity_name) {
        case 'ecom_btn_orders':
            title_activity = 'Заказ'
            break;
        // case 'ecom_btn_input':
        //     title_activity = 'Вход'
        //     break;
        case 'ecom_btn_search':
            title_activity = 'Поиск'
            break;
    }

    

    /// Если у элемента есть атрибут activity_name
    if (activity_name != null) {
        const timestamp = Date.parse(new Date());
        const date = new Date()

        
        const activity = {
            user_id: userInfo?.user_id ? userInfo.user_id : null,
            activity_name: activity_name,
            timestamp: timestamp,
            type_activity: typeActivity,
            date_action: `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timezone}`,
            title: title_activity,
            description: description
        }
        data.push(activity);
        /// Записать в local storage
        localStorage.setItem(`activity_user`, JSON.stringify(data));
    }


    if (data.length > 0) {
        let currentDate = new Date();
        let durationInMinutes = 1;
        let dateLastActivity = new Date(data[0].timestamp);
        currentDate.setMinutes(currentDate.getMinutes() - durationInMinutes);

        if (currentDate > dateLastActivity) {
            /// Отправить на сервер активность пользователя
            recInDB(authContext, timezone);
        }


    }
}

const recInDB = async (authContext, timezone) => {
    let data = getActivityFromLocalStorage()
    const result = await simpleCallBackend(authContext, '/data/app_ecomn_insert_user_activity', timezone, 'POST', { activity: JSON.stringify(data), do_not_delete_user_id_from_arg: true })

    if (result.is_ok) {
        localStorage.removeItem('activity_user');
    }
}


export const userActivity = (userContext, authContext) => {
    document.body.onclick = function (e) {
        recEventInLocalStorage(e, userContext, authContext);
    }
    document.body.onchange = function (e) {
        recEventInLocalStorage(e, userContext, authContext);
    }
    document.body.onkeydown = function (e) {
        /// Нажатие на Enter   
        if (e.keyCode === 13) {
            recEventInLocalStorage(e, userContext, authContext);
        }
    }
}


