import React, { useMemo, useContext, useState } from 'react'
import Table from '../../../common/table'
import { Box, Container, Typography, Button } from '@mui/material';
import CustomizedDialogs from './dialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { UserContext } from '../../../context/user-context';
import { makeStyles } from '@mui/styles'
import { IconButton } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';



const useStyles = makeStyles(() => ({
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#1368B1'
    }
}))


const Users = () => {
    const classes = useStyles()
    const { users, userInfo, updateUser, deleteUser, rightsСheck, supervisor, supervisorMode, loadingUsers ,setLoadUsers } = useContext(UserContext)
    const [openUser, setOpenUser] = useState({ type: '', value: false });
    const [editDataUser, setEditDataUser] = useState({})

    React.useEffect(()=>{setLoadUsers(true)},[])
    const editUserHandler = row => {
        setEditDataUser(row)
        setOpenUser({ type: 'editUser', value: true })
    }

    const unactivUserHandler = row => {
        updateUser({ user_id: row.user_id, is_active: !row.is_active })
    }

    const handleClickOpenUser = () => {
        setOpenUser({ type: 'addUser', value: true })
    }

    const deleteUserHandler = row => {
        deleteUser(row.user_id)
    }



    const columns = useMemo(
        () => [
            {
                accesor: 'email',
                Header: 'Email',
                Cell: ({ row }) => <span><b>{row?.original.email}</b></span>
            },
            {
                accesor: 'last_name',
                Header: 'Фамилия',
                Cell: ({ row }) => <span>{row?.original.last_name}</span>
            },
            {
                accesor: 'first_name',
                Header: 'Имя',
                Cell: ({ row }) => <span>{row?.original.first_name}</span>
            },

            {
                accesor: 'middle_name',
                Header: 'Отчество',
                Cell: ({ row }) => <span>{row?.original.middle_name ? row?.original.middle_name : '-'}</span>
            },
            {
                accesor: 'position_in_company',
                Header: 'Должность',
                Cell: ({ row }) => <span>{row?.original.position_in_company ? row?.original.position_in_company : '-'}</span>
            },
            {
                accesor: 'phone_number',
                Header: 'Телефон',
                Cell: ({ row }) => <span>{row?.original.phone_number ? row?.original.phone_number : '-'}</span>
            },
            {
                accesor: 'role_id',
                Header: 'Права',
                Cell: ({ row }) => <span>{row?.original.role_id == 1 ? 'Полный доступ (Администратор)' : 'Контролируемый доступ (Пользователь)'}</span>
            },
            {
                Header: '',
                id: ({ row }) => { row?.original.email },
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            ((userInfo.user_id === row.original.user_id || rightsСheck()) || supervisorMode()) &&
                            <Tooltip title={'Редактировать'}>
                                <IconButton
                                    className={classes.iconContainer}
                                    sx={{ padding: '4px' }}
                                    onClick={() => editUserHandler(row.original)}
                                >
                                    <AppRegistrationIcon size={'small'} />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            (rightsСheck() || supervisorMode()) &&
                            <div>
                                <IconButton
                                    className={classes.iconContainer}
                                    sx={{ padding: '4px', margin: '0 7px' }}
                                    onClick={() => unactivUserHandler(row.original)}
                                >
                                    {
                                        row?.original.is_active ?
                                            <Tooltip title={'Выкл.'}><ToggleOnIcon size={'small'} /></Tooltip>
                                            :
                                            <Tooltip title={'Вкл.'}><ToggleOffIcon size={'small'} /></Tooltip>
                                    }
                                </IconButton>
                                <IconButton
                                    className={classes.iconContainer}
                                    sx={{ padding: '4px' }}
                                    onClick={() => deleteUserHandler(row.original)}
                                    disabled={row.original.user_id === userInfo.user_id}
                                >
                                    <DeleteForeverIcon size={'small'} />
                                </IconButton>
                            </div>
                        }
                    </div>
                )
            },
        ],
        [users, userInfo],
    );

    const tableStyles = {
        height: window.innerHeight - 380,
        rowHeight: 36,
        fontSize: 14,
        headerBgDark: '#616161',
        headerBgLight: '#d0d2d2',
        paddingFirstLastCell: '8px',
        outerBorder: true,
        rowExpandedHeight: 300,
        borderColor: '#d7d7d7',
    };

    return (
        <Container fixed maxWidth='xl' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            {
                loadingUsers ?
                    <CircularProgress style={{margin: '100px auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>
                    :
                    <div>
                        <Box paddingBottom={1}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>Администратор</Typography>
                        </Box>

                        <Box sx={{ margin: '10px 0 20px 0' }}>
                            <Typography variant="h6" gutterBottom>
                                {supervisor?.first_name}&nbsp;{supervisor?.last_name === '-' && ''}
                            </Typography>
                            <Typography component="div">
                                Email: {supervisor?.email}
                            </Typography>
                        </Box>

                        <Box paddingBottom={1}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography component="h1" variant="h5" style={{ marginTop: 10 }}>Пользователи</Typography>
                        </Box>

                        {
                            (rightsСheck() || supervisorMode()) &&
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10 }}>
                                <Button variant="contained" color="secondary" onClick={() => handleClickOpenUser()}>
                                    Добавить пользователя
                                </Button>
                            </div>
                        }

                        <CustomizedDialogs openUser={openUser} setOpenUser={setOpenUser} editDataUser={editDataUser} userType={userInfo.user_type}/>

                        <Table
                            columns={columns}
                            data={users}
                            styles={tableStyles}
                            getRowProps={(row) => {
                                return ({
                                    style: {
                                        ...(row?.original?.email === userInfo.email && { background: '#C9FFBF', fontWeight: 600 }),
                                    }
                                })
                            }}
                        />
                    </div>
            }
        </Container>
    )
}

export default Users