import { createTheme } from '@mui/material/styles'
import { blue, purple } from '@mui/material/colors'

const theme = createTheme({
	palette: {
		primary: {
			main: purple[800]
		},
		secondary: {
			main: blue[800],
		},
	},
})

export default theme