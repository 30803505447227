import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { OrdersProvider, OrdersContext } from '../../context/orders-context'

const MemoizedComponent = React.memo(({
    orders, showOnlyActive, setShowOnlyActive, groupBy, setGroupBy
})=>{
    const { Orders } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={150} height={28}/>}>
            <Orders
                orders={orders} showOnlyActive={showOnlyActive} setShowOnlyActive={setShowOnlyActive}
                groupBy={groupBy} setGroupBy={setGroupBy}
            />
        </Suspense>
    )
}, 
(prev, next) => {
    return  JSON.stringify(prev.orders) === JSON.stringify(next.orders)
            && prev.loadingOrders === next.loadingOrders
            && prev.showOnlyActive === next.showOnlyActive
            && JSON.stringify(prev.groupBy) === JSON.stringify(next.groupBy)
})

MemoizedComponent.displayName = 'memoized-orders'

function Component(){
    const { orders, showOnlyActive, setShowOnlyActive, groupBy, setGroupBy } = React.useContext(OrdersContext)
    return (
        <MemoizedComponent
            orders={orders} setShowOnlyActive={setShowOnlyActive} showOnlyActive={showOnlyActive}
            groupBy={groupBy} setGroupBy={setGroupBy}
        />
    )
}

function Orders() {
    return (
        <OrdersProvider>
            <Component/>
        </OrdersProvider>
    )
}

export default Orders