import React from 'react'
import AuthenticatedApp from './authenticated-app'
import UnauthenticatedApp from './unauthenticated-app'
import { CircularProgress } from '@mui/material'
import { YMInitializer } from 'react-yandex-metrika'
import { ThemeProvider } from '@mui/material/styles'
import { AuthContext } from './context/auth-context'
import { ThemeSettingContext, TemplateSettingContext } from './context/theme-template-context'
import { MetaContext } from './context/meta-context'
import { Route, Routes } from 'react-router-dom'
import PasswordRecovery from './components/password-recovery/default'
import { UserContext } from './context/user-context'
import { userActivity } from './utils/user_activity'
import NotWorkingPage from './components/not-working-page'
// import { SearchSkuContext } from './context/search-sku-context'



function App() {
  const authContext = React.useContext(AuthContext)
  const contextMeta = React.useContext(MetaContext)
  const { currentTemplateName } = React.useContext(TemplateSettingContext)
  const { currentTheme } = React.useContext(ThemeSettingContext)
  const userContext = React.useContext(UserContext)


  // const { chosenSku } = React.useContext(SearchSkuContext)
  // console.log('render App.js')
  // console.log('tokenAuthorizedUser=', authContext.tokenAuthorizedUser, ', unauthorizedModeIsOn=', authContext.unauthorizedModeIsOn, ', tokenUnauthorizedUser=', authContext.tokenUnauthorizedUser)
  React.useEffect(() => {
    userActivity(userContext, authContext) // Закоментить в dev 
  }, [userContext.userInfo, authContext])

  return (
    <ThemeProvider theme={currentTheme}>
      {
        contextMeta.storeUnavailable // Для dev = true, отключает сайт на время проведения технических работ
        ?
          <NotWorkingPage/>
        :
        (currentTemplateName === null || currentTheme === null)
          ?
          <CircularProgress />
          :
          <YMInitializer accounts={contextMeta.yandexMetric} options={{ webvisor: true }}>
            {
              (authContext.tokenAuthorizedUser !== null || authContext.unauthorizedModeIsOn && authContext.tokenUnauthorizedUser !== null && !authContext.entryButtonClicked)
                ?
                <div>
                  <Routes>
                    <Route path='/*' element={<AuthenticatedApp />}></Route>
                    <Route path='/forgot/password-reset' element={<PasswordRecovery />} />
                  </Routes>
                </div>
                :
                <div>
                  <Routes>
                    <Route path='/*' element={<UnauthenticatedApp />} />
                    <Route path='/forgot/password-reset' element={<PasswordRecovery />} />
                  </Routes>
                </div>
            }
          </YMInitializer>
      
      }
    </ThemeProvider>
  )
}

export default App