import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { TopProductsProvider, TopProductsContext } from '../../context/top-products-context'

const MemoizedComponent = React.memo(({
    loadingPriceList, 
    priceList, 
    setItemToAddToBasket
})=>{
    const { TopProducts } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={150} height={28}/>}>
            <TopProducts
                loadingPriceList={loadingPriceList}
                priceList={priceList} 
                setItemToAddToBasket={setItemToAddToBasket}
            />
        </Suspense>
    )
}, 
(prev, next) => {
    // console.log('brandList prev:',prev.brandList,', next:',next.brandList,', equality:',prev.brandList === next.brandList)
    // console.log('setChosenBrandList prev:',prev.setChosenBrandList,', next:',next.setChosenBrandList,', equality:',prev.setChosenBrandList === next.setChosenBrandList)
    // console.log('deliveryTimeList prev:',prev.deliveryTimeList,', next:',next.deliveryTimeList,', equality:',prev.deliveryTimeList === next.deliveryTimeList)
    // console.log('setChosenDeliveryTime prev:',prev.setChosenDeliveryTime,', next:',next.setChosenDeliveryTime,', equality:',prev.setChosenDeliveryTime === next.setChosenDeliveryTime)
    // console.log('loadingPartnerPriceList prev:',prev.loadingPartnerPriceList,', next:',next.loadingPartnerPriceList,', equality:',prev.loadingPartnerPriceList === next.loadingPartnerPriceList)
    // console.log('priceList prev:',prev.priceList,', next:',next.priceList,', equality:',JSON.stringify(prev.priceList) === JSON.stringify(next.priceList))

    return prev.loadingPriceList === next.loadingPriceList
        && JSON.stringify(prev.priceList) === JSON.stringify(next.priceList)
        && prev.setItemToAddToBasket === next.setItemToAddToBasket
}
)
MemoizedComponent.displayName = 'memoized-top-products'

function Component(){
    const { 
        loadingPriceList, 
        priceList, 
        setItemToAddToBasket
     } = React.useContext(TopProductsContext)
    return (
        <MemoizedComponent
            loadingPriceList={loadingPriceList} 
            priceList={priceList} 
            setItemToAddToBasket={setItemToAddToBasket}
        />
    )
}

function TopProducts() {
    return (
        <TopProductsProvider>
            <Component/>
        </TopProductsProvider>
    )
}

export default TopProducts