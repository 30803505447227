import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'


function Component() {

    const { DebtsApplications } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={300} height={100} />}>
            <DebtsApplications />
        </Suspense>
    )
} 

export default Component