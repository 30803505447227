import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { PriceListProvider, PriceListContext } from '../../context/price-list-context'

const MemoizedComponent = React.memo(({
    brandList, 
    setChosenBrandList, 
    deliveryTimeList, 
    setChosenDeliveryTime, 
    loadingPartnerPriceList, 
    priceList, 
    setItemToAddToBasket,
    loadingCompanyPriceList
})=>{
    const { PriceList } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={150} height={28}/>}>
            <PriceList 
                brandList={brandList} 
                setChosenBrandList={setChosenBrandList} 
                deliveryTimeList={deliveryTimeList} 
                setChosenDeliveryTime={setChosenDeliveryTime}
                loadingPartnerPriceList={loadingPartnerPriceList} 
                priceList={priceList} 
                setItemToAddToBasket={setItemToAddToBasket} 
                loadingCompanyPriceList={loadingCompanyPriceList}
            />
        </Suspense>
    )
}, 
(prev, next) => {
    return prev.brandList === next.brandList
        && prev.setChosenBrandList === next.setChosenBrandList
        && prev.deliveryTimeList === next.deliveryTimeList
        && prev.setChosenDeliveryTime === next.setChosenDeliveryTime
        && prev.loadingPartnerPriceList === next.loadingPartnerPriceList
        && JSON.stringify(prev.priceList) === JSON.stringify(next.priceList)
        && prev.loadingCompanyPriceList === next.loadingCompanyPriceList
        && prev.setItemToAddToBasket === next.setItemToAddToBasket
    }
)
MemoizedComponent.displayName = 'memoized-price-list'

function Component(){
    const { 
        brandList, 
        deliveryTimeList, 
        setChosenBrandList, 
        setChosenDeliveryTime, 
        loadingPartnerPriceList, 
        priceList, 
        setItemToAddToBasket,
        loadingCompanyPriceList
     } = React.useContext(PriceListContext)

    return (
        <MemoizedComponent
            brandList={brandList} 
            setChosenBrandList={setChosenBrandList} 
            deliveryTimeList={deliveryTimeList} 
            setChosenDeliveryTime={setChosenDeliveryTime}
            loadingPartnerPriceList={loadingPartnerPriceList} 
            priceList={priceList} 
            setItemToAddToBasket={setItemToAddToBasket}
            loadingCompanyPriceList={loadingCompanyPriceList}
        />
    )
}

function PriceList() {
    return (
        <PriceListProvider>
            <Component />
        </PriceListProvider>
    )
}

export default PriceList