import React, { memo } from 'react';
import { isString } from 'lodash';
import { areEqual } from 'react-window';
import { Tooltip } from '@mui/material';
import RenderCell from './render-cell';

import {
  CellWrapper,
  Cell,
  Row,
} from './styled-elements';

// const getVirtualRowStyle = (defaultStyle, enableResizeColumns, totalColumnsWidth) => {
//   if (enableResizeColumns) return { ...defaultStyle, width: `${totalColumnsWidth}px` };
//   return defaultStyle;
// };
const getTooltipValue = (cell) => {
  const customTooltipValue = cell?.row?.original?.[cell?.column?.tooltipAccessor];

  if (customTooltipValue) {
    return customTooltipValue;
  }
  return cell.value;
};

const RenderRow = memo(({ data, index, style }) => {
  const {
    rows,
    prepareRow,
    getRowProps,
    getColumnProps,
    getCellProps,
    getCellWrapperStyles,
    getCellStyles,
    renderRowSubComponent,
    enableResizeColumns,
    tableInnerWidth,
    totalColumnsWidth,
    diff,
  } = data;
  RenderRow.displayName = 'RenderRow'

  const row = rows[index];
  prepareRow(row);

  const hasScroll = diff > 0;
  const { ...otherStyleProps } = style;
  const resizeStyles = (tableInnerWidth + (hasScroll ? diff : 0)) >= totalColumnsWidth ? '100%' : 'auto';
  return (
    <div style={{ ...otherStyleProps, width: enableResizeColumns ? resizeStyles : '100%' }}>
      <Row {...row?.getRowProps(getRowProps(row))}>
        {row.cells.map((cell, index) => {
          const tooltipCell = getTooltipValue(cell);
          return (
            <div 
              {...cell?.getCellProps([
                { ...(cell.column?.style && { style: cell.column?.style }) },
                getColumnProps(cell.column),
                getCellProps(cell),
              ])}
              key={'cell-'+index}
            >
              <CellWrapper style={getCellWrapperStyles(cell.column)}>
                {cell.column.hasTooltip && tooltipCell ? (
                  <Tooltip title={tooltipCell ?? ''} placement="top-start">
                    <Cell
                      title={null}
                      style={getCellStyles(cell.column)}
                    >
                      <RenderCell cell={cell} />
                    </Cell>
                  </Tooltip>
                ) : (
                  <Cell 
                    title={(isString(cell.value) && !cell.column?.disableTitle) ? cell.value : null}
                    style={getCellStyles(cell.column)}
                  >
                    <RenderCell cell={cell} />
                  </Cell>
                )}
              </CellWrapper>
            </div>
          );
        })}
      </Row>
      {row.isExpanded && renderRowSubComponent({ row })}
    </div>
  );
}, areEqual);

export default RenderRow;
