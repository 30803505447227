import { makeStyles } from '@mui/styles'
import React from 'react'


const useStyles = makeStyles(() => ({
    wrapper: {
        margin: '0 auto',
        width: '1200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
    },
}))

function AppWrapper( { children } ) {

    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            { children }
        </div>
    )
}

export default AppWrapper