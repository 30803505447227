import React, {useContext, useState, useEffect, createContext} from 'react'
import simpleCallBackend from '../../utils/api-client'
import timezoneDetermine from '../../utils/timezone-determine'
import { AuthContext} from '../../context/auth-context'
import { AppContext } from '../../context/app-context'


const ApplicationsContext = createContext()

function ApplicationsProvider(props) {
    const contextAuth = useContext(AuthContext)
    const { cartInfoRefetchCounter } = useContext(AppContext)
    const timezone = timezoneDetermine()
    const [clientInfo, setClientInfo] = useState({})

    useEffect(()=>{
        simpleCallBackend(contextAuth, '/data/app_ecomn_get_contractor_info', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
        .then(data => {
            setClientInfo(data?.data)
        })
    }, [cartInfoRefetchCounter, contextAuth.tokenUnauthorizedUser, contextAuth.tokenAuthorizedUser])

    return (
        <ApplicationsContext.Provider
        value={{
            clientInfo
        }}
        {...props}
        />
    )
}

export { ApplicationsProvider, ApplicationsContext }