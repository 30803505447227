import React, { useState } from "react";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Link,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "./components/button";
import Copyright from "./common/Copyright";
import { AuthContext } from "./context/auth-context";
import { MetaContext } from "./context/meta-context";
import { useSnackbar } from "notistack";
import simpleCallBackend from "./utils/api-client";
import timezoneDetermine from "./utils/timezone-determine";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "cetner",
  },
  icon: {
    position: "absolute",
    left: "65%",
    top: "18%",
  },
});

const UnauthenticatedApp = () => {
  const {
    setEmail,
    setPassword,
    setLoginButtonClicked,
    loginButtonClicked,
    emailAndPasswordNotValid,
    helpEnabled,
    helpText,
  } = React.useContext(AuthContext);
  const { logo } = React.useContext(MetaContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");
  const [loadingFargot, setLoadingFargot] = useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailForgot(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleForgotPassword = async (userData) => {
    setLoadingFargot(true);
    const timezone = timezoneDetermine();
    const result = await simpleCallBackend(
      null,
      "/send_recovery_link",
      timezone,
      "POST",
      { email: userData }
    );
    if (result.is_ok) {
      enqueueSnackbar(result.message, {
        variant: "success",
        autoHideDuration: 5000,
      });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
    setLoadingFargot(false);
  };

  const handleLoginButtonClick = async () => {
    setLoginButtonClicked(true);
  };

  return (
    <Grid sx={{ marginTop: "20vh" }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src={"data:image/png;base64, " + logo}
            alt="logo"
            style={{ maxWidth: 500 }}
          />
          <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
            {showForgotPassword ? "Востановление пароля" : "Авторизация"}
          </Typography>
          {showForgotPassword ? (
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Введите электронную почтy"
                name="email"
                autoComplete="email"
                autoFocus
                value={emailForgot}
                onChange={handleEmailChange}
                error={emailAndPasswordNotValid}
                sx={{ marginTop: "20px" }}
              />
              <Button
                name="Отправить"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loadingFargot}
                onClick={() => handleForgotPassword(emailForgot)}
                sx={{ margin: "20px 0 20px 0" }}
                startIcon={
                  loadingFargot && (
                    <CircularProgress
                      className={classes.icon}
                      color="inherit"
                      size={22}
                    />
                  )
                }
              ></Button>
              <Link
                color="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => setShowForgotPassword(false)}
              >
                Вернуться к авторизации
              </Link>
            </div>
          ) : (
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Электронная почта"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleEmailChange}
                error={emailAndPasswordNotValid}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handlePasswordChange}
                error={emailAndPasswordNotValid}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Запомнить"
              />
              <FormHelperText disabled={!helpEnabled} error={helpEnabled}>
                {helpText}
              </FormHelperText>

              <Box sx={{ m: 1, position: "relative", width: "100%" }}>
                <Button
                  name="Войти"
                  // type="submit"
                  fullWidth
                  disabled={loginButtonClicked}
                  variant="contained"
                  onClick={() => {
                    handleLoginButtonClick();
                  }}
                />
                {loginButtonClicked && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>

              <Link
                color="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => setShowForgotPassword(true)}
              >
                Забыли пароль?
              </Link>
            </div>
          )}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Grid>
  );
};

export default UnauthenticatedApp;
