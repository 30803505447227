import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import WarehouseMap from "./map";

const Warehouse = ({
  propsName,
  propsPhone,
  propsGraphic = null,
  propsPerson = null,
  propsAdress,
  propsEmail,
  propsCoordinates,
}) => {
  return (
    <div style={{ paddingLeft: 0 }}>
      <Typography variant="h5">{propsName}</Typography>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Card style={{ width: "500px", marginRight: 20 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 16 }}
              color="text.secondary"
              gutterBottom
            >
              Контактный телефон:
              <br />
            </Typography>
            <p style={{ fontSize: 20, marginTop: 0 }}>{propsPhone}</p>
          </CardContent>
          <CardContent
            sx={{
              display: propsPerson ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontSize: 16 }}
              color="text.secondary"
              gutterBottom
            >
              Контактное лицо:
              <br />
            </Typography>
            <p style={{ fontSize: 20, marginTop: 0 }}>{propsPerson}</p>
          </CardContent>
          <CardContent>
            <Typography
              sx={{ fontSize: 16, display: propsGraphic ? "flex" : "none" }}
              color="text.secondary"
              gutterBottom
            >
              График работы: {propsPhone}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography sx={{ fontSize: 16 }} gutterBottom>
              Вы можете задать вопрос по электронной почте:
              <a href="mailto:${propsEmail}">{propsEmail}</a>
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ width: "500px" }}>
          <CardContent>
            <Typography variant="h6">{propsAdress}</Typography>
            <WarehouseMap props={propsCoordinates} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Warehouse;
