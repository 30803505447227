import React, { useCallback, useReducer } from 'react';
import { isString } from 'lodash';
import {
  Layers as LayersIcon,
  LayersClear as LayersClearIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  HeaderGroup as HeaderGroupStyled,
  CellWrapper,
  Cell,
} from './styled-elements';
import {
  Separator,
  SortIconUp,
  SortIconDown,
  ArrowIconUp,
  ArrowIconDown,
} from '../icons';
import MenuColumnControls from './menu-column-controls';
import MenuColumn from './menu-column';
import { getColumnHeaderStyle } from './utils';

const headerMenuInitialState = {};
const headerMenuReducer = (state, action) => {
  switch (action.type) {
    case 'openMenu': {
      return {
        ...state,
        menuTarget: action?.payload?.menuTarget,
        menuTargetColumn: action?.payload?.menuTargetColumn,
      };
    }
    case 'closeMenu': {
      return {
        ...state,
        menuTarget: null,
        menuTargetId: null,
      };
    }

    case 'reset':
      return headerMenuInitialState;
    default:
      throw new Error();
  }
};

const useStyles = makeStyles(() => ({
  cellWrapper: {
    '&:hover': {
      width: 'calc(100% - 1em)',
    },
  },
  columnHeader: {
    '&:hover .menuColumnControls': {
      display: 'flex',
    },
  },
}));

const HeaderGroup = (props) => {
  const {
    headerGroup,
    headerRef,
    getColumnProps,
    getHeaderProps,
    getCellWrapperStyles,
    disableAbsoluteSortArrows,
    listRef,
    enableMenuColumnControls,
    disableHeaderGroupedIcons,
    enableResizeColumns,
    data,
    // filters,
    // preFilteredRows,
    // setAllFilters,
    // rows,
    // preGlobalFilteredRows,
  } = props;
  const classes = useStyles();
  const [stateHeaderMenu, dispatchHeaderMenu] = useReducer(headerMenuReducer, headerMenuInitialState);

  const onGroup = useCallback((column) => {
    if (!column.canGroupBy) return;
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
    column.toggleGroupBy();
  }, []);
  const onSort = useCallback((column) => {
    if (!column.canSort) return;
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
    column.toggleSortBy();
  }, []);
  const renderSortArrows = useCallback((column, disableAbsolute) => {
    if (!column.isSorted) return null;
    const getStyle = () => {
      if (!disableAbsolute) {
        return {
          position: 'absolute',
          paddingTop: '35px',
          zIndex: 10,
        };
      }
      return { display: 'flex', alignItems: 'center' };
    };

    if (disableAbsolute) {
      return (
        <div style={getStyle()}>
          {column.isSortedDesc
            ? <ArrowIconDown style={{ width: '0.9em', fontSize: '1em' }} />
            : <ArrowIconUp style={{ width: '0.9em', fontSize: '1em' }} />}
        </div>
      );
    }

    return (
      <div style={getStyle()}>
        {column.isSortedDesc
          ? <SortIconDown />
          : <SortIconUp />}
      </div>
    );
  }, []);
  const renderGroupedIcons = useCallback((column) => {
    if (disableHeaderGroupedIcons) return null;
    if (!column.canGroupBy) return null;
    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          onGroup(column);
        }}
        style={{
          paddingRight: '3px',
        }}
      >
        {column.isGrouped ? <LayersIcon fontSize="inherit" /> : <LayersClearIcon fontSize="inherit" />}
      </span>
    );
  }, []);

  return (
    <HeaderGroupStyled {...headerGroup.getHeaderGroupProps()} ref={headerRef}>
      {
        headerGroup.headers.map((column, index) => {
          return (
            <div
              {...column.getHeaderProps(
                [
                  getColumnHeaderStyle(column),
                  getColumnProps(column),
                  getHeaderProps(column),
                ],
              )}
              className={classes.columnHeader}
              key={'elem-'+index}
            >
              <CellWrapper style={{ ...getCellWrapperStyles(column) }}>
                {renderSortArrows(column, disableAbsoluteSortArrows)}
                <Cell
                  {...column.getSortByToggleProps({ title: '', style: { zIndex: 100 } })}
                  title={isString(column.Header) ? column.Header : null}
                  onClick={(e) => {
                    if (column.isResizing) return;
                    e.preventDefault();
                    e.stopPropagation();
                    onSort(column);
                  }}
                >
                  {!enableMenuColumnControls && renderGroupedIcons(column)}
                  {column.render('Header')}
                </Cell>
                {enableMenuColumnControls && (
                  <MenuColumnControls
                    column={column}
                    stateHeaderMenu={stateHeaderMenu}
                    dispatchHeaderMenu={dispatchHeaderMenu}
                  />
                )}
              </CellWrapper>
              {
                (enableResizeColumns && column.id !== 'expander') && (
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                  >
                    <Separator
                      style={{
                        fill: '#abaaaa',
                        fontSize: 'inherit',
                        width: '1.3em',
                        height: '1.3em',
                      }}
                    />
                  </div>
                )
              }
            </div>
          );
        })
      }
      <MenuColumn
        stateHeaderMenu={stateHeaderMenu}
        dispatchHeaderMenu={dispatchHeaderMenu}
        data={data}
      />
    </HeaderGroupStyled>
  );
};

export default HeaderGroup;
