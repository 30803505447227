import { useState, useEffect } from "react";

function useWidth(elementRef, type = "clientWidth") {
  const [width, setWidth] = useState(null);

  const updateWidth = () => {
    if (elementRef && elementRef) {
      switch (type) {
        case "bounding":
          {
            setWidth(elementRef.current.getBoundingClientRect().width);
            break;
          }
        case "clientWidth":
          setWidth(elementRef.current.clientWidth);
          break;
        // case 'innerWidth':
        //   setWidth(elementRef.current.innerWidth);
        //   break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [updateWidth]);

  return [width];
}

export default useWidth;
