import React, { createContext } from "react";
import { AuthContext } from "../../context/auth-context";
import timezoneDetermine from "../../utils/timezone-determine";
import simpleCallBackend from "../../utils/api-client";

const ContactsContext = createContext();

const ContactsProvider = (props) => {
  const contextAuth = React.useContext(AuthContext);
  const timezone = timezoneDetermine();

  const [companyContactList, setCompanyContactList] = React.useState([]);

  const handleGetCompanyContactList = () => {
    simpleCallBackend(
      contextAuth,
      "/data/app_ecomn_get_company_contact_list",
      timezone,
      "POST",
      {}
    )
      .then((data) => {
        if (Array.isArray(data?.data)) setCompanyContactList(data.data);
        else setCompanyContactList([]);
      })
      .catch(() => {
        setCompanyContactList([]);
      });
  };

  React.useEffect(() => {
    handleGetCompanyContactList();
  }, []);

  return (
    <ContactsContext.Provider
      value={{
        companyContactList,
      }}
      {...props}
    />
  );
};

export { ContactsProvider, ContactsContext };
