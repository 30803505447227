import React from "react";
import { useNavigate } from "react-router-dom";

const MainContentContext = React.createContext();

const MainContentProvider = (props) => {
  const [dataContent, setDataContent] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [presearchPin, setPresearchPin] = React.useState("");
  const [result, setResult] = React.useState([]);
  const [resultPartner, setResultPartner] = React.useState([]);
  const [usePresearch, setUsePresearch] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    getContent();
    getParameters();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (usePresearch) {
      setPresearchPin(event.target[0].value.split(" ")[0]);
      navigate("/presearch");
    }
  };
  const getParameters = () => {
    fetch("/use_presearch", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setUsePresearch(data?.use_presearch === "True" ? true : false);
      });
  };

  const getContent = () => {
    fetch("/content", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setDataContent(data.content ? data.content : []);
      });
  };

  return (
    <MainContentContext.Provider
      value={{
        dataContent,
        inputValue,
        setInputValue,
        presearchPin,
        result,
        setResult,
        resultPartner,
        setResultPartner,
        handleSubmit,
      }}
      {...props}
    />
  );
};

export { MainContentProvider, MainContentContext };
