export const getDefaultStyles = (inputStyles, muiTheme) => {
    return ({
      height: 450,
      rowHeight: 36,
      rowHeaderHeight: 36,
      fontSize: 14,
      headerBgDark: '#616161',
      headerBgLight: '#dfedfc',
      headerTextColor: inputStyles?.headerTextColor
        ?? muiTheme.palette.type === 'dark'
        ? inputStyles?.headerTextColorDark ?? muiTheme.palette.text.primary
        : inputStyles?.headerTextColorLight ?? muiTheme.palette.text.primary,
      paddingFirstLastCell: '8px',
      outerBorder: true,
      rowExpandedHeight: 300,
      borderColor: inputStyles?.borderColor
        ?? muiTheme.palette.type === 'dark'
        ? inputStyles?.borderColorDark ?? '#757575'
        : inputStyles?.borderColorLight ?? '#a9a7a7',
      hoverRowBackgroud: muiTheme.palette.type === 'dark' ? inputStyles?.hoverRowBackgroudDark : inputStyles?.hoverRowBackgroudLight,
    });
  };
  
  export const getColumnHeaderStyle = (column) => {
    const style = {
      display: 'flex',
    };
  
    if (column?.style) {
      return { style: { ...style, ...column?.style } };
    }
    return { style };
  };
  