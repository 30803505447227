import { createTheme } from '@mui/material/styles'
import { orange } from '@mui/material/colors'

const theme = createTheme({
	palette: {
		primary: {
			main: orange[500]
		},
		secondary: {
			main: orange[500],
		},
		info: {
			main: '#1F1209',
		},
	},
})	

export default theme