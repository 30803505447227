import React from 'react'
import { Container, CssBaseline, Box, Typography, TextField, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from '../../../components/button'
import Copyright from '../../../common/Copyright'
import { MetaContext } from '../../../context/meta-context'
import { UserContext } from '../../../context/user-context'
import { useSearchParams} from 'react-router-dom' 



const useStyles = makeStyles({
    paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'cetner'
},
icon: {
    position: 'absolute',
    left: '65%',
    top: '18%'
}
})

function PasswordRecovery() {
    
    const errorCode = [
        {
            id:0,
            value:'пароли не совпадают'
        },
        {
            id:1,
            value:'пароль должен содержать от 6 до 20 символов, в нем можно использовать цифры, символы и буквы латинского алфавита. При этом обязательно в пароле должна быть хотя бы одна цифра, одна буква в нижнем регистре и одна буква в верхнем регистре'
        }
    ]
    const passwordRule =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
    const { logo } = React.useContext(MetaContext)
    const { updateUser } = React.useContext(UserContext)
    const classes = useStyles()
    const [newPassword, setNewPassword] = React.useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('')
    const [sending, setSending] = React.useState(true)
    const [passwordErrorCode,setPasswordErrorCode] = React.useState(-1)

    const [searchParams] = useSearchParams()
    const key = searchParams.get('key')
    const id = searchParams.get('id')

    const handleNewPassword = (event) => {
        setNewPassword(event.target.value)
    }
    const handleNewPasswordConfirm = (event) => {
        setNewPasswordConfirm(event.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!passwordRule.test(newPassword)){
            setPasswordErrorCode(1)
        }else if (newPassword !== newPasswordConfirm){
            setPasswordErrorCode(0)
        }else{
            updateUser({
                newPassword: newPassword,
                activation_key: key,
                user_id: id
            })
            setSending(false)
            setPasswordErrorCode(-1)
        }
    }

    return (
        <Grid sx={{ marginTop: '20vh' }}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={'data:image/png;base64, ' + logo} alt="logo" style={{ maxWidth: 500 }} />
                    {sending ? 
                    <div>
                    <Typography component="h1" variant="h5" align='center' sx={{ mt: '20px'}}>
                        введите новый пароль
                    </Typography>
                    <Box
                    onSubmit={handleSubmit}
                    component="form"
                    type='form'>
                        <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={passwordErrorCode !== -1?true:false}
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleNewPassword}
                        />
                         <TextField
                        variant="outlined"
                        margin="normal"
                        error={passwordErrorCode !== -1?true:false}
                        required
                        fullWidth
                        name="password-confirm"
                        label="Подтверждение пароля"
                        type="password"
                        id="password-confirm"
                        autoComplete="current-password"
                        helperText={passwordErrorCode !== -1?errorCode[passwordErrorCode].value:''}
                        onChange={handleNewPasswordConfirm}
                        />
                        <Button
                        name='Отправить'
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={ handleSubmit }
                        sx={{ margin: '20px 0 20px 0' }}
                        />
                    </Box>
                    </div>
                    :
                    <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
                        Пароль успешно обновлен!
                    </Typography>
                    }
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </Grid>
    )
}

export default PasswordRecovery