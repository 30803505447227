import React from 'react'
import useLocalStorageState from 'use-local-storage-state'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
// import { setUserActivity } from '../utils/user_activity/set_activity_user'


const AuthContext = React.createContext()

function AuthProvider(props) {

  const [unauthorizedModeIsOn, setNonAuthorizedModeIsOn] = useLocalStorageState('unauthorizedModeIsOn', { defaultValue: false })
  const [unauthorizedUserIdentity, setUnauthorizedUserIdentity] = useLocalStorageState('unauthorizedUserIdentity', { defaultValue: null })
  const [tokenUnauthorizedUser, setTokenUnauthorizedUser] = useLocalStorageState('tokenUnautorizedUser', { defaultValue: null })
  const [entryButtonClicked, setEntryButtonClicked] = React.useState(false)

  const [password, setPassword] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [emailAndPasswordNotValid, setEmailAndPasswordNotValid] = React.useState(false)
  const [helpEnabled, setHelpEnabled] = React.useState(false)
  const [helpText, setHelpText] = React.useState('')
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false)
  const [tokenAuthorizedUser,  setTokenAuthorizedUser] = useLocalStorageState('tokenAutorizedUser', { defaultValue: null })
  const [userStatus, setUserStatus] = useLocalStorageState('userStatus', { defaultValue: null })
  const [userId, setUserId] = React.useState(null)
  const [temp, setTemp] = React.useState(null)

  React.useEffect(()=>{
    const timezone = timezoneDetermine()
    if (!tokenAuthorizedUser) {
      setUserStatus(null)
      setUserId(null)
    }
    if (tokenAuthorizedUser === undefined){
      setTokenAuthorizedUser(null)
    }
    simpleCallBackend(null, '/identify_unauthorized', timezone, 'POST', { unauthorized_user_identity: unauthorizedUserIdentity })
    .then(data => {
        setNonAuthorizedModeIsOn(data?.unauthorized_mode_is_on)
        if (data?.is_ok) {
          setUnauthorizedUserIdentity(data?.unauthorized_user_identity)
          setTokenUnauthorizedUser(data?.token)
        }
        else
        {
          setUnauthorizedUserIdentity(null)
          setTokenUnauthorizedUser(null)
        }
      
    })
  }, [])

  React.useEffect(() => {
    if(loginButtonClicked)
    {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/login', timezone, 'POST', { email, password })
      .then(data => {
        setEmailAndPasswordNotValid(!data?.is_ok)
        if (data?.is_ok) {
          setTemp(JSON.parse(JSON.stringify(data)))
          setHelpText('')
          setHelpEnabled(false)
        }
        else
        {
          setHelpText(data?.message)
          setHelpEnabled(true)
        }    
        setLoginButtonClicked(false)
      })


    }
  },[loginButtonClicked])

  React.useEffect(()=>{
    if(temp?.user_id !== null && temp?.token){
      setUserId(temp?.user_id)
      setUserStatus(temp?.user_status ?? null)
      setEntryButtonClicked(false)
      setTokenAuthorizedUser(temp?.token) // редирект происходит здесь
      setPassword('')
      setEmail('')
    }
  },[temp])




  return (
    <AuthContext.Provider
      value={{
        unauthorizedUserIdentity, setUnauthorizedUserIdentity,
        unauthorizedModeIsOn, tokenUnauthorizedUser,
        setEmail, setPassword, setLoginButtonClicked,loginButtonClicked,
        emailAndPasswordNotValid, helpEnabled, helpText,
        tokenAuthorizedUser, setTokenAuthorizedUser,
        entryButtonClicked, setEntryButtonClicked,setTemp,
        userStatus, setUserStatus, 
        userId, setUserId
      }}
      {...props}
    />
  )
}

export { AuthProvider, AuthContext }
