import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const NotWorkingPage = () => {
  const useStyles = makeStyles((theme) => ({
    textBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "600px",
      width: "800px",
      backgroundColor: theme.palette.secondary.main,
    },
  }));
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box className={classes.textBox}>
        <Typography
          variant="h2"
          sx={{
            color: "white",
          }}
        >
          Технический перерыв
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "white",
            backgroundColor: "none",
            textAlign: "center",
            mt: "40px",
          }}
        >
          Наши специалисты проводят работы по улучшению интернет-магазина,
          <br />в скором времени он станет снова доступен!
        </Typography>
      </Box>
    </Box>
  );
};

export default NotWorkingPage;
