import { createTheme } from '@mui/material/styles'
import { blue } from '@mui/material/colors'

const theme = createTheme({
	palette: {
		primary: {
			main: '#CFD2D2'
		},
		secondary: {
			main: blue[800],
		},
		info: {
			main: '#1F1209',
		}
	},
})	

export default theme