import { CardContent } from '@mui/material'
import React from 'react'

export const VideoContent = ({ props }) => {

    return (

    <CardContent key={props.id} style={{display:'flex', justifyContent:'center'}}>
        <iframe src={props.content}
            width="800" 
            height="400"
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
        />
    </CardContent>
    )
}
