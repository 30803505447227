import { createTheme } from '@mui/material/styles'
import { blue } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: blue[800]
    },
    secondary: {
      main: blue[800],
    },
    info: {
			main: '#fff',
		}
  },
})
	
export default theme			
