import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';
import { get } from 'lodash';
import {
  Popover,
  Paper,
  Box,
  ListItemText,
  Checkbox,
  Divider,
  MenuItem,
} from '@mui/material';

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
`;

const useStyles = makeStyles(() => ({
  popoverPaper: {
    maxHeight: '50vh',
  },
  checkbox: {
    fontSize: '12px',
    height: '20px',
  },
}));

const getUniqValues = (rows, path) => {
  const nonUniq = rows?.map((item) => get(item, path));
  return [...(new Set(nonUniq)).values()];
};

const GroupItem = ({ column }) => {
  let actionName = 'Сгруппировать';
  if (!column?.canGroupBy) {
    return null;
  }
  if (column?.isGrouped) {
    actionName = 'Разгруппировать';
  }
  return (
    <MenuItem onClick={() => column?.toggleGroupBy()} dense>
      {actionName}
    </MenuItem>
  );
};

const CheckboxValues = (props) => {
  const {
    column,
    columnValues,
    columnId,
  } = props;
  // const classes = useStyles();
  const currentFilterValues = column.filterValue ?? [];
  // const [searchInput, setSearchInput] = useState('');

  const handleCheckboxClick = (item) => () => {
    if (!column.filterValue?.includes(item)) {
      column.setFilter([...currentFilterValues, item]);
      return;
    }
    column.setFilter([...currentFilterValues.filter((value) => value !== item)]);
  };
  const handleCheckboxCheckall = () => {
    if (currentFilterValues?.length > 0) {
      column.setFilter([]);
      return;
    }
    column.setFilter(columnValues);
  };

  return (
    <div>
      {/* <TextField
        fullWidth
        id="search"
        label="Поиск"
        size="small"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      /> */}
      <MenuItem style={{ paddingTop: '16px', paddingBottom: '16px', height: '20px' }} onClick={handleCheckboxCheckall}>
        <StyledCheckbox checked={!column.filterValue?.length > 0} />
        <StyledListItemText
          primary="Выделить всё"
          style={{ fontSize: '10px', paddingLeft: '0px', paddingRight: '12px' }}
        />
      </MenuItem>
      <Divider />
      {columnValues?.map((item) => (
        <div key={`${columnId}_${item}`}>
          <MenuItem style={{ paddingTop: '0px', paddingBottom: '0px', height: '20px' }} onClick={handleCheckboxClick(item)}>
            <StyledCheckbox color="primary" checked={!column.filterValue?.includes(item)} />
            <StyledListItemText
              primary={item}
              style={{ fontSize: '10px', paddingLeft: '0px', paddingRight: '12px' }}
            />
          </MenuItem>
        </div>
      ))}

    </div>
  );
};

const MenuColumn = (props) => {
  const classes = useStyles();
  const {
    stateHeaderMenu,
    dispatchHeaderMenu,
    data,
  } = props;
  const column = stateHeaderMenu.menuTargetColumn;
  const columnId = column?.id;
  const columnValues = useMemo(() => getUniqValues(data, [column?.id]), [data, column]);

  if (!stateHeaderMenu.menuTargetColumn) return null;
  if (!column?.canFilter && !column?.canGroupBy) return null;

  return (
    <Popover
      open={Boolean(stateHeaderMenu?.menuTarget)}
      anchorEl={stateHeaderMenu?.menuTarget}
      onClose={() => dispatchHeaderMenu({ type: 'closeMenu' })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={classes.popoverPaper}
    >
      <Paper>
        <Box p={1} minWidth={200} maxWidth={300}>
          {column?.canGroupBy && (
            <GroupItem
              column={column}
            />
          )}
          {column?.canFilter && (
            <CheckboxValues
              column={column}
              columnValues={columnValues}
              columnId={columnId}
            />
          )}
        </Box>
      </Paper>
    </Popover>
  );
};

export default MenuColumn;
