import React from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { AuthContext} from './auth-context'
import { AppContext} from './app-context'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
import { useSnackbar } from 'notistack'
import { MetaContext } from './meta-context'

const filterPriceListByDeliveryTime = (companyPriceList, partnerPriceList, chosenDeliveryTime) => {
    let newPriceList = []

    if(chosenDeliveryTime?.delivery_time_formatted === 'Любой срок')
    {
        //is_partner_sku
        for(let i = 0; i < companyPriceList.length; i++)
            newPriceList.push({...companyPriceList[i], is_partner_sku: false})
        
        for(let i = 0; i < partnerPriceList.length; i++)
            newPriceList.push({...partnerPriceList[i], is_partner_sku: true})
    }
    else if(chosenDeliveryTime?.delivery_time_formatted === 'Не указан')
    {
        for(let i = 0; i < partnerPriceList.length; i++)
        {
            const deliveryTime = partnerPriceList[i]?.delivery_time
            if(deliveryTime == null)
                newPriceList.push({...partnerPriceList[i], is_partner_sku: true})
        }
    }
    else
    {
        const deliveryTime = parseInt(chosenDeliveryTime?.delivery_time)
        if(!isNaN(deliveryTime) && deliveryTime >= 0)
        {
            for(let i = 0; i < companyPriceList.length; i++)
                newPriceList.push({...companyPriceList[i], is_partner_sku: false})

            for(let i = 0; i < partnerPriceList.length; i++)
            {
                if(partnerPriceList[i]?.delivery_time <= deliveryTime)
                    newPriceList.push({...partnerPriceList[i], is_partner_sku: true})
            }
        }
    }

    return newPriceList
}

const filterPriceListByBrand = (priceList, chosenBrandList) => {
    let newPriceList = []

    for(let i = 0; i < priceList.length; i++)
    {
        for(let j = 0; j < chosenBrandList.length; j++)
        {
            let brandInPriceList = priceList[i]?.brand
            let brandInChosenBrandList = chosenBrandList[j]?.brand

            if(brandInPriceList === brandInChosenBrandList || brandInPriceList == null && brandInChosenBrandList == null)
            {
                newPriceList.push({...priceList[i]})
                break
            }
        }
    }

    return newPriceList
}

const usePriceList = (companyPriceList, partnerPriceList, chosenBrandList, chosenDeliveryTime) => {
    let priceList = filterPriceListByDeliveryTime(companyPriceList, partnerPriceList, chosenDeliveryTime)
    priceList = filterPriceListByBrand(priceList, chosenBrandList)

    return priceList
}

const useBrandList = (companyPriceList, partnerPriceList) => {
    const [brandList, setBrandList] = React.useState([])

    React.useEffect(()=>{
        let newBrandList = []
        let newBrandDict = {}

        if(Array.isArray(companyPriceList))
            for(let i = 0; i < companyPriceList.length; i++)
            {
                let brand = companyPriceList[i]?.brand

                if(brand) brand = brand + ''
                else brand = 'Не указан'

                if(brand in newBrandDict)
                {
                    newBrandList[newBrandDict[brand]].quantity++
                }
                else
                {
                    newBrandList.push({brand, quantity: 1})
                    newBrandDict[brand] = newBrandList.length - 1
                }
            }

        if(Array.isArray(partnerPriceList))
            for(let i = 0; i < partnerPriceList.length; i++)
            {
                let brand = partnerPriceList[i]?.brand
                
                if(brand) brand = brand + ''
                else brand = 'Не указан'

                if(brand in newBrandDict)
                {
                    newBrandList[newBrandDict[brand]].quantity++
                }
                else
                {
                    newBrandList.push({brand, quantity: 1})
                    newBrandDict[brand] = newBrandList.length - 1
                }
            }

        newBrandList.sort(function (a, b) {
            if (a.quantity < b.quantity) {
                return 1;
            }
            if (a.quantity > b.quantity) {
                return -1;
            }
            if (a.brand < b.brand) {
                return -1;
            }
            if (a.brand > b.brand) {
                return 1;
            }
            return 0;
        })

        setBrandList(newBrandList)
    },[companyPriceList, partnerPriceList])
  
    return brandList
}

const useDeliveryTimeList = (companyPriceList, partnerPriceList) => {
    const [deliveryTimeList, setDeliveryTimeList] = React.useState([])

    React.useEffect(()=>{
        let newDeliveryTimeList = []
        let newDeliveryTimeDict = {}

        if(Array.isArray(companyPriceList))
        {
            if(companyPriceList.length > 0)
            {
                const delivery_time = 0;
                const delivery_time_formatted = 'В наличии'
                newDeliveryTimeList.push({delivery_time, delivery_time_formatted})
                newDeliveryTimeDict[delivery_time] = newDeliveryTimeList.length - 1
            }
        }

        if(Array.isArray(partnerPriceList))
            for(let i = 0; i < partnerPriceList.length; i++)
            {
                const delivery_time = partnerPriceList[i]?.delivery_time
                let delivery_time_formatted = partnerPriceList[i]?.delivery_time_formatted
                
                if(delivery_time) delivery_time_formatted = delivery_time_formatted + ''
                else delivery_time_formatted = 'Не указан'

                if(!(delivery_time in newDeliveryTimeDict))
                {
                    newDeliveryTimeList.push({delivery_time, delivery_time_formatted})
                    newDeliveryTimeDict[delivery_time] = newDeliveryTimeList.length - 1
                }
            }

            newDeliveryTimeList.sort(function (a, b) {
            if (a.delivery_time < b.delivery_time) {
                return -1;
            }
            if (a.delivery_time > b.delivery_time) {
                return 1;
            }
            return 0;
        })

        setDeliveryTimeList(newDeliveryTimeList)
    },[companyPriceList, partnerPriceList])
  
    return deliveryTimeList
}

const PriceListContext = React.createContext()

const PriceListProvider = (props) => {
  const contextAuth = React.useContext(AuthContext)
  const metaContext = React.useContext(MetaContext)
  const { setCartInfoRefetchCounter } = React.useContext(AppContext)
  const timezone = timezoneDetermine()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [params, setParams] = useSearchParams()
  const [loadingCompanyPriceList, setLoadingCompanyPriceList] = React.useState(false)
  const [companyPriceList, setCompanyPriceList] = React.useState([])
  const [loadingPartnerPriceList, setLoadingPartnerPriceList] = React.useState(false)
  const [partnerPriceList, setPartnerPriceList] = React.useState([])

  const [chosenBrandList, setChosenBrandList] = React.useState([])
  const [chosenDeliveryTime, setChosenDeliveryTime] = React.useState({})
  const [priceList, setPriceList] = React.useState([])
  const brandList = useBrandList(companyPriceList, partnerPriceList)
  const deliveryTimeList = useDeliveryTimeList(companyPriceList, partnerPriceList)

  const [itemToAddToBasket, setItemToAddToBasket] = React.useState({})
 
//   console.log('render PriceListProvider')


  //Запрос прайс-листов
  React.useEffect(()=>{
    if(location?.pathname === '/search')
    {  
      const sku_id = params.get('sku_id') === 'partnership' ? null : params.get('sku_id')
      const description = params.get('description')
      const pin = params.get('pin')
      const brand = params.get('brand')

      //Запрос прайс-листа собственных товаров
      setCompanyPriceList([])
    if(!metaContext.useQwepCrossForCompanyLoading){
        if(metaContext.useQwepCrossForCompany){
            const url = '/partner/check_qwep_cross'
            const body = {article:pin, brand:brand}
            let  crosses = []
            simpleCallBackend(null, url, timezone, 'POST',body ).then(data => {
                if (data?.is_ok)
                    crosses = data?.message
                else {
                    fetch('/report-error', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json;charset=utf-8' },
                        body: JSON.stringify({ error: data?.message?.code, stack: `При обращении к QWEP за информацией о кроссах, система вернула ошибку: ${data?.error?.code} : ${data?.error?.message}`}),
                        json: true,
                    })
                }
                simpleCallBackend(contextAuth, '/data/app_ecomn_get_company_price_list_qwep_cross', timezone, 'POST', { sku_id, pin, brand, description, do_not_delete_user_id_from_arg: true,crosses })
                .then(data => {
                    if(Array.isArray(data?.data))
                    setCompanyPriceList(data.data)
                    else
                    setCompanyPriceList([])
                })
                .catch(()=>{
                    setCompanyPriceList([])
                })
            })
            .catch(err =>{
                fetch('/report-error', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify({ error: err , stack: `При обращении к ${url}, система вернула ошибку: ${err}`}),
                    json: true,
                })
                simpleCallBackend(contextAuth, '/data/app_ecomn_get_company_price_list_qwep_cross', timezone, 'POST', { sku_id, pin, brand, description, do_not_delete_user_id_from_arg: true,crosses:[] })
                .then(data => {
                    if(Array.isArray(data?.data))
                        setCompanyPriceList(data.data)
                    else
                        setCompanyPriceList([])

                })
                .catch(()=>{
                    setCompanyPriceList([])
                })
                // .finally(()=>{
                //     if (!metaContext.checkPartnerQWEP)
                //         setLoadingCompanyPriceList(false)
                // })
            })
        }else{
            setLoadingCompanyPriceList(true)
            setCompanyPriceList([])
            simpleCallBackend(contextAuth, '/data/app_ecomn_get_company_price_list', timezone, 'POST', { sku_id, pin, brand, description, do_not_delete_user_id_from_arg: true })
            .then(data => {
                if(Array.isArray(data?.data))
                    setCompanyPriceList(data.data)
            })
            .catch((err)=>{
                console.log(err)
            })
            .finally(()=>{
                setTimeout(()=>setLoadingCompanyPriceList(false),[500])
            })
        }

      //Запрос прайс-листа партнеров
      if (metaContext.checkPartnerQWEP) {
        setLoadingPartnerPriceList(true)
        setPartnerPriceList([])
        simpleCallBackend(contextAuth, '/data/app_ecomn_get_partner_price_list', timezone, 'POST', { sku_id, pin, brand, description, in_stock: metaContext.partnerCheckInStock, do_not_delete_user_id_from_arg: true })
        .then(data => {
            if(Array.isArray(data?.data))
            setPartnerPriceList(data.data)
            else
            setPartnerPriceList([])

            setLoadingPartnerPriceList(false)
        })
        .catch(()=>{
            setPartnerPriceList([])
        })
      }
    }
    }
  },[location,metaContext.useQwepCrossForCompanyLoading])

  //Формирование объединенного прайс-листа собственного и партнерского товара, а также фильтрация по брендам и времени поставки
  React.useEffect(()=>{
    setPriceList(usePriceList(companyPriceList, partnerPriceList, chosenBrandList, chosenDeliveryTime))
  },[companyPriceList, partnerPriceList, chosenBrandList, chosenDeliveryTime])

  //Добавление в корзину
  React.useEffect(()=>{
    if('sku_id' in itemToAddToBasket || 'item_id' in itemToAddToBasket)
    {
        simpleCallBackend(contextAuth, '/data/app_ecomn_add_to_cart', timezone, 'POST', {
            action: 'ADD',
            cart_id: null,
            is_partner_sku: itemToAddToBasket?.is_partner_sku,
            sku_id: itemToAddToBasket?.sku_id,
            item_id: itemToAddToBasket?.item_id,
            partner_search_log_id: itemToAddToBasket?.partner_search_log_id,
            quantity: itemToAddToBasket?.quantity,
            warehouse_id: itemToAddToBasket?.warehouse_id,
            do_not_delete_user_id_from_arg: true, 
            delivery_time: itemToAddToBasket?.delivery_time
        })
        .then(data => {
            setCartInfoRefetchCounter(counter => counter + 1)
            if(data?.is_ok)
                enqueueSnackbar('Товар '+itemToAddToBasket?.pin+' '+itemToAddToBasket?.brand+' в количестве '+itemToAddToBasket?.quantity+' шт успешно добавлен в корзину', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            else
                enqueueSnackbar('Возникла ошибка при добавлении товара в корзину: '+data?.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
        })

    }
  },[itemToAddToBasket])

  return (
    <PriceListContext.Provider
      value={{
        setParams,
        loadingCompanyPriceList, 
        loadingPartnerPriceList, 
        companyPriceList, 
        partnerPriceList,
        brandList, 
        deliveryTimeList,
        chosenBrandList, 
        setChosenBrandList, 
        chosenDeliveryTime, 
        setChosenDeliveryTime,
        priceList,
        setItemToAddToBasket
      }}
      {...props}
    />
  )
}

export { PriceListProvider, PriceListContext, useBrandList, useDeliveryTimeList }

