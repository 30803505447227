import { Typography } from '@mui/material'
import React from 'react'

export const TextContent = ({ props }) => {

    return (
    
    <div key={props.id}>
        <Typography 
        style={{marginTop: 20}}
        gutterBottom 
        variant="h5" 
        component="div">
            {props.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {props.content}
        </Typography>
    </div>
    )
}
