import { CardMedia } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import React from 'react'
import MobileStepper from '@mui/material/MobileStepper';
// import Button from '@mui/material/Button';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const BannerContent = ({ props }) => {


    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.content.length
        
    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };   

    return (
    
    <div style={{margin: '10px 0 10px 0'}}>
        <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        > 
            {props.content.map((step, index) => (
                <div 
                style={{display:'flex', justifyContent:'center'}}
                key={step.image}>
                {Math.abs(activeStep - index) <= 1 ? (
                    step.link ?
                    <a href={step?.link}>
                        <CardMedia
                        style={{width: +props.width, height: +props.height}}
                        alt={props.content[activeStep]?.name}
                        image={props.content[activeStep]?.image}
                        />
                    </a>
                    :
                    <CardMedia
                        style={{width: +props.width, height: +props.height}}
                        alt={props.content[activeStep]?.name}
                        image={props.content[activeStep]?.image}
                        />
                ) : null}
                </div>
            ))} 
            
        </AutoPlaySwipeableViews>
            <MobileStepper
            style={{position:'absolute', top:'8%', height:'30px', backgroundColor:'transparent'}}
            variant="none"
            banners={maxSteps}
            activeStep={activeStep}
            // nextButton={
            //     <Button
            //     size="large"
            //     onClick={handleNext}
            //     disabled={activeStep === maxSteps - 1}
            //     >
            //     вперед
            //     {theme.direction === 'rtl' ? (
            //         <KeyboardArrowLeft />
            //     ) : (
            //         <KeyboardArrowRight />
            //     )}
            //     </Button>
            // }
            // backButton={
            //     <Button 
            //     size="large" 
            //     onClick={handleBack} 
            //     disabled={activeStep === 0}>
            //     {theme.direction === 'rtl' ? (
            //         <KeyboardArrowRight />
            //     ) : (
            //         <KeyboardArrowLeft />
            //     )}
            //     назад
            //     </Button>
            // }
            />
    </div>
    )
}