import React from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { AuthContext } from './auth-context'
import { MetaContext } from './meta-context'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'


const PresearchContext = React.createContext()
const PresearchProvider = (props) => {
  const navigate = useNavigate()
  const contextAuth = React.useContext(AuthContext)
  const metaContext = React.useContext(MetaContext)
  const [chosenSku, setChosenSku] = React.useState({})
  const [result, setResult] = React.useState([])
  const [presearchLoading, setPresearchLoading] = React.useState([])
  const [inputPresearch, setInputPresearch] = React.useState('')


  React.useEffect(()=>{
    if (inputPresearch && typeof inputPresearch ==='string' && inputPresearch.length >= 3){
            setPresearchLoading(true)
            setResult([])
            const timezone = timezoneDetermine()
            simpleCallBackend(contextAuth, '/api_fast_presearch_sku', timezone, 'POST', { article: inputPresearch, limit_results: metaContext.limitTooltipForSearch })
              .then(data => {
                console.log(data)
                if (Array.isArray(data?.data)) {
                    setResult(data.data)
                }
                else{
                    setResult([])
                }
                setPresearchLoading(false)
              })
              .catch((error)=>{
                console.log(error)
                setPresearchLoading(false)
              })
            }
  },[inputPresearch])


  React.useEffect(() => {
    if (typeof chosenSku === 'object' && chosenSku !== null) {
      if ('pin' in chosenSku) {
        navigate({
          pathname: '/search',
          search: `?${createSearchParams({
            sku_id: chosenSku.sku_id ?? 'partnership',
            pin: chosenSku.pin,
            brand: chosenSku.brand,
            description: chosenSku.description,
          })}`,
        })
      }
    }
  }, [chosenSku])

  return (
    <PresearchContext.Provider
      value={{
        setChosenSku:setChosenSku,
        presearchLoading,
        inputPresearch, 
        setInputPresearch,
        result
      }}
      {...props}
    />
  )
}

export { PresearchProvider, PresearchContext }

