import React, { useContext, createContext, useEffect, useState } from 'react'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
import { useSnackbar } from 'notistack'
import { AuthContext } from './auth-context'
import { useNavigate } from 'react-router-dom'


const UserContext = createContext()

function UserProvider(props) {
    const { enqueueSnackbar } = useSnackbar()
    const authContext = useContext(AuthContext)
    const timezone = timezoneDetermine()
    const [supervisor, setSupervisor] = useState({})
    const [users, setUsers] = useState([])
    const [userInfo, setUserInfo] = useState({})

    const [loadingUsers, setLoadingUsers] = useState(false)
    const [loadUsers, setLoadUsers] = useState(false)
    const navigate = useNavigate()
    

    useEffect( async () => {
        setLoadingUsers(true)
        await getUsers()
        setLoadingUsers(false)
    }, [authContext.loginButtonClicked, authContext.userStatus, authContext.userId])

    
    useEffect( async () => {
        if(loadUsers)
       { 
            setLoadingUsers(true)
            await getUsers()
            setLoadingUsers(false)
            setLoadUsers(false)
        }
    }, [loadUsers])

    const getUsers = async () => {
        if (authContext.tokenAuthorizedUser) {
            const result = await simpleCallBackend(authContext, '/data/app_ecomn_get_user_in_admin_account', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
            if (result.data.length) {
                const users = []
                result.data.map(user => {
                    if (user.is_current === true) {
                        setUserInfo(user)
                    }
                    if (user.user_type === 'admin_accounting_system') {
                        setSupervisor(user)
                    } else if (user.user_id === authContext.userId) {
                        users.unshift(user)
                    } else {
                        users.push(user)
                    }
                })
                setUsers(users)
            }
        }
    }

    // console.log('__ADMIN__', supervisor);
    // console.log('__USER__', users);
    // console.log('__USER_INFO__', userInfo);


    const setUser = async (data) => {
        if (authContext.tokenAuthorizedUser) {
            const dataUser = {
                ...data,
                photo: data.photo ?? '',
                middle_name: data.middle_name ?? '',
                phone_number: data.phone_number ?? '',
                position_in_company: data.position_in_company ?? '',
                role_id: data.role_id ?? '2',
            }
            const result = await simpleCallBackend(authContext, '/data/app_ecomn_set_user', timezone, 'POST', { ...dataUser, do_not_delete_user_id_from_arg: true })
            if (result.is_ok) {
                getUsers()
                enqueueSnackbar(result.message, {
                    variant: 'success',
                    autoHideDuration: 3000,
                })
            } else {
                enqueueSnackbar(result.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            }

        }
    }


    const updateUser = async (data) => {
        if (authContext.tokenAuthorizedUser) {
            const dataUser = {
                user_editable: data.user_id,
                email: data.email ?? null,
                first_name: data.first_name ?? null,
                last_name: data.last_name ?? null,
                password: data.password ?? null,
                photo: data.photo ?? null,
                middle_name: data.middle_name ?? null,
                phone_number: data.phone_number ?? null,
                position_in_company: data.position_in_company ?? null,
                role_id_editable_user: data.role_id ?? null,
                is_active: data.is_active ?? null,
                order_on: data.order_on ?? null,
            }
            const result = await simpleCallBackend(authContext, '/data/app_ecomn_update_user_in_admin_account', timezone, 'POST', { ...dataUser, do_not_delete_user_id_from_arg: true })
            if (result.is_ok) {
                getUsers()
                enqueueSnackbar(result.message, {
                    variant: 'success',
                    autoHideDuration: 3000,
                })
            } else {
                enqueueSnackbar(result.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            }

        }else{
            const dataUser = {
                user_id: data.user_id,
                activation_key: data.activation_key,
                password: data.newPassword ?? null,
            }
            const result = await simpleCallBackend(authContext, '/data/app_ecomn_update_user_password', timezone, 'POST', {...dataUser, do_not_delete_user_id_from_arg: true })
            if (result.is_ok) {
                enqueueSnackbar(result.message, {
                    variant: 'success',
                    autoHideDuration: 3000,
                })
                setTimeout(()=>{navigate({pathname: '/'})},[3000]) 
            } else {
                enqueueSnackbar(result.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            }
        }
    }

    
    const deleteUser = async (deleted_user_id) => {
        if (authContext.tokenAuthorizedUser) {
            const result = await simpleCallBackend(authContext, '/data/app_ecomn_delete_user_in_admin_account', timezone, 'POST', { deleted_user_id, do_not_delete_user_id_from_arg: true })
            if (result.is_ok) {
                getUsers()
                enqueueSnackbar(result.message, {
                    variant: 'success',
                    autoHideDuration: 3000,
                })
            } else {
                enqueueSnackbar(result.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            }
        }
    }


    const rightsСheck = () => {
        if (userInfo.role_id === 1) {
            return true
        } else {
            return false
        }
    }

    const supervisorMode = () => {
        if (supervisor.user_id === userInfo.user_id) {
            return true
        } else {
            return false
        }
    }


    return (
        <UserContext.Provider
            value={{
                getUsers,
                setUser,
                users,
                userInfo,
                setUserInfo,
                updateUser,
                deleteUser,
                rightsСheck,
                supervisor,
                supervisorMode,
                setLoadUsers,

                loadingUsers
            }}
            {...props}
        />
    )
}

export { UserProvider, UserContext }