import React from 'react'
import { useLocation} from 'react-router-dom'
import { AuthContext} from './auth-context'
import { AppContext} from './app-context'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
import { useSnackbar } from 'notistack'
     
const CartContext = React.createContext()

const CartProvider = (props) => {
  const contextAuth = React.useContext(AuthContext)
  const { setCartInfoRefetchCounter } = React.useContext(AppContext)
  const timezone = timezoneDetermine()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [loadingCart, setLoadingCart] = React.useState(false)
  const [cart, setCart] = React.useState([])
  const [itemToRemoveFromCart, setItemToRemoveFromCart ] = React.useState({})
  const [itemToUpdateCart, setItemToUpdateCart] = React.useState({})
  const [purchaseRequestInfo, setPurchaseRequestInfo] = React.useState({})
  const [purchaseResponseInfo, setPurchaseResponseInfo] = React.useState({})
  const [purchasing, setPurchasing] = React.useState(false)
  const [blockingOrderDialog, setBlockingOrderDialog] = React.useState(false)
  const [defaultDeliveryAddress,setDefaultDeliveryAddress] = React.useState([])
  const [deliveryChoiceIsActive, setDeliveryChoiceIsActive] = React.useState(false)
  const [checkedCartData,setCheckedCartData] = React.useState([])
  
  React.useEffect(()=>{
    simpleCallBackend(null, '/delivery_choice_is_active', timezone, 'GET').then(data => {
      setDeliveryChoiceIsActive(data.delivery_choice_is_active === 'True' ? true : false)
      if (data.delivery_choice_is_active === 'True'){
        simpleCallBackend(contextAuth, '/data/app_ecomn_get_default_delivery_address_v2', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
        .then(
          result=>{
            setDefaultDeliveryAddress(result.data)
          }
        )
      }
    })
  },[])

const handleRefreshCart = () =>{
  setLoadingCart(true)
  simpleCallBackend(contextAuth, '/data/app_ecomn_get_cart', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
  .then(data => {
    setCart(data?.data)
    setLoadingCart(false)
    setCartInfoRefetchCounter(counter => counter + 1)
  })
  .finally(()=>{setLoadingCart(false)})
}

const handleCheckCart = () =>{
      simpleCallBackend(
        contextAuth,
        "/data/app_ecomn_check_cart",
        timezone,
        "POST",
        { do_not_delete_user_id_from_arg: true }
      ).then((res) => {
        if (res.is_ok) {
          if (Array.isArray(res.data)) setCheckedCartData(res.data);
          else {
            setCheckedCartData([]);
          }
        }
      });
    }

const handleUpdatePrice = (cartId) =>{
  simpleCallBackend(
    contextAuth,
    "/data/app_ecomn_update_price",
    timezone,
    "POST",
    { do_not_delete_user_id_from_arg: true, cart_id: cartId }
  ).then((res) => {
    if (res.is_ok) {
      if (res.data.is_ok ==='success')
        handleRefreshCart()
    }
  });
}

  //Обновление корзины
  React.useEffect(()=>{
    if('sku_id' in itemToUpdateCart || 'item_id' in itemToUpdateCart)
    {
        const options = {
          action: 'UPDATE',
          cart_id: itemToUpdateCart?.cart_id,
          is_partner_sku: itemToUpdateCart?.is_partner_sku,
          sku_id: itemToUpdateCart?.sku_id,
          item_id: itemToUpdateCart?.item_id,
          partner_search_log_id: itemToUpdateCart?.partner_search_log_id,
          quantity: itemToUpdateCart?.quantity,
          warehouse_id: itemToUpdateCart?.warehouse_id,
          do_not_delete_user_id_from_arg: true, 
      }
        simpleCallBackend(contextAuth, '/data/app_ecomn_add_to_cart', timezone, 'POST', options)
        .then(data => {
            setItemToUpdateCart({})
            if(data?.is_ok)
                enqueueSnackbar('Товар '+itemToUpdateCart?.pin+' '+itemToUpdateCart?.brand+' в количестве '+itemToUpdateCart?.quantity+' шт успешно обновлен в корзине', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            else
                enqueueSnackbar('Возникла ошибка при обновлении корзины: '+data?.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
        })

    }
  },[itemToUpdateCart])

  const handleSetCheckedItemInCart = (cartId, checked) => {
    console.log(`itemId ${cartId}, checked ${checked}`)
    if (cartId) {
      const options = {
        item_id: cartId,
        checked,
      };
      simpleCallBackend(
        contextAuth,
        "/data/app_ecomn_set_checked_item_in_cart",
        timezone,
        "POST",
        options
      ).then((data) => {
        if (data?.is_ok) handleRefreshCart();
        else
          enqueueSnackbar(
            "Возникла ошибка при обновлении корзины: " + data?.message,
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
      });
    }
  };

  //Удаление из корзины
  React.useEffect(()=>{
    if('cart_id' in itemToRemoveFromCart)
    {
        simpleCallBackend(contextAuth, '/data/app_ecomn_add_to_cart', timezone, 'POST', {
            action: 'DELETE',
            cart_id: itemToRemoveFromCart?.cart_id,
            is_partner_sku: itemToRemoveFromCart?.is_partner_sku,
            sku_id: itemToRemoveFromCart?.sku_id,
            item_id: itemToRemoveFromCart?.item_id,
            partner_search_log_id: itemToRemoveFromCart?.partner_search_log_id,
            quantity: itemToRemoveFromCart?.quantity,
            warehouse_id: itemToRemoveFromCart?.warehouse_id,
            do_not_delete_user_id_from_arg: true, 
        })
        .then(data => {
          setItemToRemoveFromCart({})
          setCartInfoRefetchCounter(counter => counter + 1)
          if(data?.is_ok)
              enqueueSnackbar('Товар '+itemToRemoveFromCart?.pin+' '+itemToRemoveFromCart?.brand+' в количестве '+itemToRemoveFromCart?.quantity+' шт успешно удален из корзины', {
                  variant: 'success',
                  autoHideDuration: 5000,
              })
          else
              enqueueSnackbar('Возникла ошибка при удалении из корзины: '+data?.message, {
                  variant: 'error',
                  autoHideDuration: 5000,
              })
        })
    }
  },[itemToRemoveFromCart])

  //Получение корзины
  React.useEffect(()=>{
    if(location?.pathname === '/cart')
    {
      handleRefreshCart()
    }
  },[itemToRemoveFromCart, itemToUpdateCart, purchaseResponseInfo])

  //Оформление покупки
  React.useEffect(()=>{
    if('comment' in purchaseRequestInfo)
    {
      setPurchasing(true)
      simpleCallBackend(contextAuth, '/data/app_ecomn_purchase', timezone, 'POST', 
        {
          comment: purchaseRequestInfo?.comment,
          phone: purchaseRequestInfo?.phone,
          person_name: purchaseRequestInfo?.person_name,
          organization: purchaseRequestInfo?.organization,
          delivery_address: purchaseRequestInfo?.deliveryInfo,
          check_cart: purchaseRequestInfo?.cart,
          do_not_delete_user_id_from_arg: true
      })
      .then(data => {
        if (data?.status === 'blocking') {
          setCheckedCartData(data.data)
          setBlockingOrderDialog(true)
          setPurchasing(false)
        } else {
          setPurchaseResponseInfo(data)
          setPurchasing(false)
        }
      })
      .catch(error => {
        console.log('app_ecomn_purchase error',error)
        setPurchasing(false)
      })
    }
  },[purchaseRequestInfo])

  return (
    <CartContext.Provider
      value={{
        loadingCart,
        cart,
        setItemToUpdateCart,
        setItemToRemoveFromCart,
        setPurchaseRequestInfo,
        purchasing,
        purchaseResponseInfo,
        setPurchaseResponseInfo,
        blockingOrderDialog, 
        setBlockingOrderDialog,
        deliveryChoiceIsActive,
        defaultDeliveryAddress,
        checkedCartData,
        setCheckedCartData,
        handleCheckCart,
        handleUpdatePrice,
        handleSetCheckedItemInCart
      }}
      {...props}
    />
  )
}

export { CartProvider, CartContext}


  