import React from 'react'
import { makeStyles } from '@mui/styles'
const { MetaContext } = require('../../../context/meta-context')

const useStyles = makeStyles(() => ({
  phoneNumber: {
      position: 'absolute',
      top: '15%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'gray'
  },
}))

function Hotline() {
  const classes = useStyles()
  const { hotline } = React.useContext(MetaContext)


  function hotlineNumbers() {
    let newArr =[]
    Object?.keys(hotline).forEach(function(key) {
        if (this[key].length > 0) {
        newArr.push(this[key]);
        }
    }, hotline);
    
    return newArr.length > 0 &&
      <div>
          <span className={classes.phoneNumber}> Телефон горячей линии: {newArr.map((el, i) => i == newArr.length -1 ? 
            <a key={i} href={`tel:${el}`}>{el} </a> : 
            <a key={i} href={`tel:${el}`}>{el + ';'} </a>)} 
          </span>
      </div>
}


  return (
    <div>{hotline && hotlineNumbers()}</div>
  )
}

export default Hotline