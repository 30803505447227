import React from 'react';
import {
  UnExpandIcon,
  ExpandIcon,
} from '../icons';

const ExpanderIcon = ({ isAllExpanded }) => {
  return isAllExpanded ? <UnExpandIcon /> : <ExpandIcon />;
};

const ExpanderHeader = (props) => {
  const {
    groupBy,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
    onExpand,
  } = props;

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        marginBottom: '6px',
      }}
      onClick={(e) => {
        e.stopPropagation();
        toggleAllRowsExpanded(!isAllRowsExpanded);
        onExpand();
      }}
    >
      {groupBy?.length > 0 && <ExpanderIcon isAllExpanded={isAllRowsExpanded} />}
    </div>
  );
};

export default ExpanderHeader;
