import React, { Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import { TemplateSettingContext } from "../../context/theme-template-context";
import { TiresProvider, TiresContext } from "./tires-context";

const MemoizedComponent = React.memo(
  ({
    priceList,
    setActiveType,
    activeType,
    parameterList,
    handleChangeFilterList,
    handShowBtnClicked,
    setItemToAddToBasket,
    itemToAddToBasket,
    handleClearFilterList,
    selectedFilterListTires,
    handleChangeMachineType,
    handleBtnInfo,
    currentSkuInfo,
    clearBtnClicked,
    loadingPriceList
  }) => {
    const { Tires } = React.useContext(TemplateSettingContext);
    return (
      <Suspense
        fallback={<Skeleton variant="rectangular" width={1200} height={500} />}
      >
        <Tires
          priceList={priceList}
          setActiveType={setActiveType}
          activeType={activeType}
          parameterList={parameterList}
          handleChangeFilterList={handleChangeFilterList}
          handShowBtnClicked={handShowBtnClicked}
          setItemToAddToBasket={setItemToAddToBasket}
          itemToAddToBasket={itemToAddToBasket}
          handleClearFilterList={handleClearFilterList}
          selectedFilterListTires={selectedFilterListTires}
          handleChangeMachineType={handleChangeMachineType}
          handleBtnInfo={handleBtnInfo}
          currentSkuInfo={currentSkuInfo}
          clearBtnClicked={clearBtnClicked}
          loadingPriceList={loadingPriceList}
        />
      </Suspense>
    );
  },
  (prev, next) => {
    return (
      JSON.stringify(prev.priceList) === JSON.stringify(next.priceList) &&
      prev.activeType === next.activeType &&
      prev.parameterList === next.parameterList &&
      prev.selectedFilterListTires === next.selectedFilterListTires &&
      prev.currentSkuInfo === next.currentSkuInfo &&
      prev.clearBtnClicked === next.clearBtnClicked &&
      prev.loadingPriceList === next.loadingPriceList &&
      prev.itemToAddToBasket === next.itemToAddToBasket
      
    );
  }
);

MemoizedComponent.displayName = "memoized-tires-price-list"; //'memoized-usedParts'

function Component() {
  const {
    priceList,
    setActiveType,
    activeType,
    parameterList,
    handleChangeFilterList,
    handShowBtnClicked,
    setItemToAddToBasket,
    itemToAddToBasket,
    handleClearFilterList,
    selectedFilterListTires,
    handleChangeMachineType,
    handleBtnInfo,
    currentSkuInfo,
    clearBtnClicked,
    loadingPriceList
  } = React.useContext(TiresContext);
  return (
    <MemoizedComponent
      priceList={priceList}
      setActiveType={setActiveType}
      activeType={activeType}
      parameterList={parameterList}
      handleChangeFilterList={handleChangeFilterList}
      handShowBtnClicked={handShowBtnClicked}
      setItemToAddToBasket={setItemToAddToBasket}
      itemToAddToBasket={itemToAddToBasket}
      handleClearFilterList={handleClearFilterList}
      selectedFilterListTires={selectedFilterListTires}
      handleChangeMachineType={handleChangeMachineType}
      handleBtnInfo={handleBtnInfo}
      currentSkuInfo={currentSkuInfo}
      clearBtnClicked={clearBtnClicked}
      loadingPriceList={loadingPriceList}
    />
  );
}

function Tires() {
  return (
    <TiresProvider>
      <Component />
    </TiresProvider>
  );
}

export default Tires;
