import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { CartProvider, CartContext } from '../../context/cart-context'
import { AuthContext } from '../../context/auth-context'
import { UserContext } from '../../context//user-context'
import { MetaContext } from './../../context/meta-context'

const MemoizedComponent = React.memo(({
    cart, loadingCart, setItemToUpdateCart, setItemToRemoveFromCart, tokenAuthorizedUser,
    setPurchaseRequestInfo, purchasing, purchaseResponseInfo, setPurchaseResponseInfo,
    setBlockingOrderDialog, blockingOrderDialog, deliveryChoiceIsActive ,defaultDeliveryAddress,
    checkedCartData, userInfo, partOrderOn, handleSetCheckedItemInCart
}) => {
    const { Cart } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={150} height={28} />}>
            <Cart
                loadingCart={loadingCart}
                cart={cart}
                setItemToUpdateCart={setItemToUpdateCart}
                setItemToRemoveFromCart={setItemToRemoveFromCart}
                tokenAuthorizedUser={tokenAuthorizedUser}
                blockingOrderDialog={blockingOrderDialog}
                setBlockingOrderDialog={setBlockingOrderDialog}
                setPurchaseRequestInfo={setPurchaseRequestInfo}
                purchasing={purchasing}
                purchaseResponseInfo={purchaseResponseInfo}
                setPurchaseResponseInfo={setPurchaseResponseInfo}
                deliveryChoiceIsActive={deliveryChoiceIsActive}
                defaultDeliveryAddress={defaultDeliveryAddress}
                checkedCartData={checkedCartData}
                userInfo={userInfo}
                partOrderOn={partOrderOn}
                handleSetCheckedItemInCart={handleSetCheckedItemInCart}
            />
        </Suspense>
    )
},
    (prev, next) => {
        return JSON.stringify(prev.cart) === JSON.stringify(next.cart)
            && prev.loadingCart === next.loadingCart
            && prev.purchasing === next.purchasing
            && prev.defaultDeliveryAddress === next.defaultDeliveryAddress
            && prev.deliveryChoiceIsActive === next.deliveryChoiceIsActive
            && JSON.stringify(prev.purchaseResponseInfo) === JSON.stringify(next.purchaseResponseInfo)
            && prev.blockingOrderDialog === next.blockingOrderDialog
            && JSON.stringify(prev.checkedCartData) === JSON.stringify(next.checkedCartData)  
            && JSON.stringify(prev.partOrderOn) === JSON.stringify(next.partOrderOn)   
            && JSON.stringify(prev.userInfo) === JSON.stringify(next.userInfo)   
    })

MemoizedComponent.displayName = 'memoized-cart'

function Component() {
    const { cart, loadingCart, setItemToUpdateCart, setItemToRemoveFromCart,
        setPurchaseRequestInfo, purchasing, purchaseResponseInfo, setPurchaseResponseInfo,
        blockingOrderDialog, setBlockingOrderDialog, deliveryChoiceIsActive ,defaultDeliveryAddress,checkedCartData,
        handleSetCheckedItemInCart
    } = React.useContext(CartContext)
    const { tokenAuthorizedUser } = React.useContext(AuthContext)
    const { userInfo } = React.useContext(UserContext)
    const { partOrderOn } = React.useContext(MetaContext) 

    return (
        <MemoizedComponent
            cart={cart}
            blockingOrderDialog={blockingOrderDialog}
            setBlockingOrderDialog={setBlockingOrderDialog}
            loadingCart={loadingCart}
            setItemToUpdateCart={setItemToUpdateCart}
            setItemToRemoveFromCart={setItemToRemoveFromCart}
            tokenAuthorizedUser={tokenAuthorizedUser}
            setPurchaseRequestInfo={setPurchaseRequestInfo}
            purchasing={purchasing}
            purchaseResponseInfo={purchaseResponseInfo}
            setPurchaseResponseInfo={setPurchaseResponseInfo}
            deliveryChoiceIsActive={deliveryChoiceIsActive}
            defaultDeliveryAddress={defaultDeliveryAddress}
            checkedCartData={checkedCartData}
            userInfo={userInfo}
            partOrderOn={partOrderOn}
            handleSetCheckedItemInCart={handleSetCheckedItemInCart}
        />
    )
}

function Cart() {
    return (
        <CartProvider>
            <Component />
        </CartProvider>
    )
}

export default Cart