import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack'
import { UserContext } from '../../../context/user-context';


export default function AddUser({setOpenUser}) {
    const { setUser } = React.useContext(UserContext)
    const [form, setForm] = React.useState({})
    const { enqueueSnackbar } = useSnackbar()

    const handleChange = e => {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleSubmit = () => {
        const validForm = {}
        const errors = []
        for (const [key, value] of Object.entries(form)) {
            switch (key) {
                case 'email':
                    /\S+@\S+\.\S+/.test(value.trim()) ?
                        validForm[key] = value.trim()
                        : errors.push(' Введен некорректный email')
                    break;
                case 'first_name':
                    value.trim() ?
                        validForm[key] = value.trim()
                        : errors.push(' Введите имя')
                    break;
                case 'last_name':
                    value.trim() ?
                        validForm[key] = value.trim()
                        : errors.push(' Введите фамилию')
                    break;
                default:
                    validForm[key] = value.trim()
            }
        }
        if (errors.length) {
            enqueueSnackbar(errors.join(), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } else {
            setUser(validForm)
            setOpenUser({type: '', value: false})
        }
    }

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 2, width: 520, display: 'flex', flexDirection: 'column', justifyContent: 'center' },
            }}
            noValidate
        >
            <TextField
                id="outlined-name"
                label="Почта*"
                name='email'
                value={form.email || ''}
                onChange={handleChange}
                autoComplete={'new-password'}
            />
            <TextField
                id="outlined-name"
                name='first_name'
                label="Имя*"
                value={form.first_name || ''}
                onChange={handleChange}
            />
            <TextField
                id="outlined-name"
                name='last_name'
                label="Фамилия*"
                value={form.last_name || ''}
                onChange={handleChange}
            />
            <TextField
                id="outlined-name"
                name='middle_name'
                label="Отчество"
                value={form.middle_name || ''}
                onChange={handleChange}
            />
            <TextField
                id="outlined-name"
                name='phone_number'
                label="Телефон"
                value={form.phone_number || ''}
                onChange={handleChange}
            />
             <TextField
                id="outlined-name"
                name='position_in_company'
                label="Должность"
                value={form.position_in_company || ''}
                onChange={handleChange}
            />
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Права доступа</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name='role_id'
                    value={form.role_id || '2'}
                    label="Права доступа*"
                    onChange={handleChange}

                >
                    <MenuItem value={'1'}>Полный доступ (Администратор)</MenuItem>
                    <MenuItem value={'2'}>Контролируемый доступ (Пользователь)</MenuItem>
                </Select>
            </FormControl>
            <Button onClick={handleSubmit} color="secondary" variant="outlined">Сохранить</Button>
        </Box>
    );
}