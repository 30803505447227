import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const theme = createTheme({
	palette: {
		primary: {
			main: red[600]
		}, 
		secondary: {
			main: red[600]
		},
		common: {
			
		},
	},
})
	
export default theme