import React, { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import SkuSearcher from './components/sku-searcher'
import Button from './components/button'
import AppBar from './components/appbar'
import { Routes, Route } from 'react-router-dom'
import PriceList from './components/price-list'
import Presearch from './components/presearch'
import Cart from './components/cart'
import TopProducts from './components/top-products'
import Orders from './components/orders'
import { DabtsApplication } from './applications'
import AppWrapper from './AppWrapper'
import Users from './components/users/default'
import Main from './applications/main/default'
// import Contacts from './applications/contacts/default'
import Hotline from './components/informations/default'
import Logo from './components/informations/default/logo'
import { MainContentContext } from './context/main-content-contex'
import Contractors from './components/contractors/default'
import { MetaContext } from './context/meta-context'
import Guarantee from './applications/guarantee'
import Wheels from './applications/wheels'
import Contacts from './applications/contacts'
import Tires from './applications/tires'
// import Footer from './components/footer/default'
const { AuthContext } = require ('./context/auth-context')
const { SearchSkuProvider } = require('./context/search-sku-context')
const { ApplicationsContext } = require('./applications/context/applications-context')




const itemsMenu = [
    { title: 'Главная', url: '/main', sys_name: 'main_page', component: <Main /> }, 
    { title: 'Топ продаж', url: '/', sys_name: 'top_products_page', component: <TopProducts /> }, 
    { title: 'Заказы', url: '/orders', sys_name: 'orders_page', component: <Orders /> }, 
    { title: 'Пользователи', url: '/users', sys_name: 'users_page', component: <Users /> },
    { title: 'Контрагент', url: '/contractors', sys_name: 'contractors_page', component: <Contractors />},
    { title: 'Гарантия', url: '/guarantee', sys_name: 'guarantee_page', component: <Guarantee />},
    { title: 'Шины', url: '/tires', sys_name: 'tires_page', component: <Tires />},
    { title: 'Диски', url: '/wheels', sys_name: 'wheels_page', component: <Wheels />},
    { title: 'Контакты', url: '/contacts', sys_name: 'contacts', component: <Contacts />}
]

const useStyles = makeStyles(() => ({
    app: {
        paddingTop: '20px'
    },
    container: {
        display: 'flex', 
        alignItems: 'center', 
        flexDirection: 'column',
    },
    serchBox: {
        position: 'absolute',
        top: '75%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
    },
    appBar: {
        padding: '10px', 
        marginTop: '1.1%',
    }
}))

function AuthenticatedApp() {
    const classes = useStyles()
    const { userStatus } = useContext(AuthContext)
    const [menu, setMenu] = useState([])
    const { dataContent,inputValue,setInputValue,setResult,handleSubmit,presearchPin} = React.useContext(MainContentContext)
    const { clientInfo } = React.useContext(ApplicationsContext)
    const { topProductsPage, topMainPage, startPage, tiresPage, wheelsPage, contactsPage } = React.useContext(MetaContext)

    // const btnHandlerClick = () => {
    //     console.log(`press btn entry, значение в skuSearcher=`,searchSkuContext.inputValue)
    //     navigate('/presearch');
    // }

    useEffect(() => {
        const unlockMenu = []
        itemsMenu.map(item => {
            switch (item.title) {
                case 'Топ продаж':
                    if (topProductsPage) {
                        unlockMenu.push(item)
                    }
                    break;
                case 'Пользователи':
                    if (userStatus) {
                        unlockMenu.push(item)
                    }
                    break;
                case 'Контрагент':
                    if (clientInfo?.length) {
                        unlockMenu.push(item)
                    }
                    break;
                case 'Главная':
                    if (dataContent.length && topMainPage) {
                        unlockMenu.push(item)
                    }
                    break;
                case 'Гарантия':
                    if (clientInfo && clientInfo[0]?.guarantee)
                    unlockMenu.push(item)
                    break;
                case 'Шины':
                    if (tiresPage)
                    unlockMenu.push(item)
                    break;
                case 'Диски':
                    if (wheelsPage)
                    unlockMenu.push(item)
                    break;
                case 'Контакты':
                    if (contactsPage)
                    unlockMenu.push(item)
                    break;
                default:
                    unlockMenu.push(item)
                    break;
            }
        })
        setMenu(unlockMenu)
    }, [dataContent.length, clientInfo, userStatus])
    

    return (
        <div>
            <div className={classes.app}>
                <div className={classes.container}>
                    <AppWrapper>
                        <Logo />
                        <Hotline />
                        <SearchSkuProvider>
                            <form  onSubmit={handleSubmit} className={classes.serchBox}>
                                <SkuSearcher inputValue={inputValue} setInputValue={setInputValue} setResult={setResult}/>
                                <Button style={{marginLeft: '16px'}} color={'secondary'} name='ПОИСК' />
                            </form>
                        </SearchSkuProvider>
                        <DabtsApplication />
                    </AppWrapper>
                    <AppBar className={classes.appBar} menuItems={menu} startPage={startPage}/>
                </div>
            </div>
            <AppWrapper>
            <Routes>
                <Route path="/search" element={<PriceList />} />
                <Route path="/presearch" element={<Presearch inputValue={presearchPin}/>}  />
                <Route path={!startPage ? '/' : "/cart"} element={<Cart />} />
                    {
                        menu.map((item, idx) => {
                            if (startPage === item.sys_name) {
                                return <Route key={idx} path={'/'} element={item.component} />
                            } else {
                                return <Route key={idx} path={item.url} element={item.component} />
                            }
                        })
                    }
                </Routes>
            </AppWrapper>
            {/* <Footer /> */}
        </div>
    )
}

export default AuthenticatedApp