import React from 'react'
import { AuthContext} from './auth-context'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
     
const OrdersContext = React.createContext()

const OrdersProvider = (props) => {
  const contextAuth = React.useContext(AuthContext)
  const timezone = timezoneDetermine()
  const [loadingOrders, setLoadingOrders] = React.useState(false)
  const [orders, setOrders] = React.useState([])
  const [showOnlyActive, setShowOnlyActive] = React.useState(true)
  const [groupBy, setGroupBy] = React.useState(['buyer_order_id'])

  //Получение корзины
  React.useEffect(()=>{
    if(loadingOrders)
    {
      simpleCallBackend(contextAuth, '/data/app_ecomn_get_orders', timezone, 'POST',
        {
          show_only_active: showOnlyActive,
          do_not_delete_user_id_from_arg: true 
      })
      .then(data => {
        setOrders(data?.data)
        setLoadingOrders(false)
      })
      .catch(()=>{
        setLoadingOrders(false)
      })
    }
  },[loadingOrders])

  React.useEffect(()=>{
    setLoadingOrders(true)
  },[showOnlyActive])

  return (
    <OrdersContext.Provider
      value={{
        orders,
        showOnlyActive,
        setShowOnlyActive,
        groupBy,
        setGroupBy
      }}
      {...props}
    />
  )
}

export { OrdersProvider, OrdersContext}


  