import React, { Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import { TemplateSettingContext } from "../../context/theme-template-context";
import { WheelsProvider, WheelsContext } from "./wheels-context";

const MemoizedComponent = React.memo(
  ({
    priceList,
    loadingPriceList,
    setActiveType,
    activeType,
    parameterList,
    handleChangeFilterList,
    handShowBtnClicked,
    setItemToAddToBasket,
    itemToAddToBasket,
    handleClearFilterList,
    selectedFilterListWheels,
    handleChangeMachineType,
    handleBtnInfo,
    currentSkuInfo,
    clearBtnClicked
  }) => {
    const { Wheels } = React.useContext(TemplateSettingContext);
    return (
      <Suspense
        fallback={<Skeleton variant="rectangular" width={1200} height={500} />}
      >
        <Wheels
          loadingPriceList={loadingPriceList}
          priceList={priceList}
          setActiveType={setActiveType}
          activeType={activeType}
          parameterList={parameterList}
          handleChangeFilterList={handleChangeFilterList}
          handShowBtnClicked={handShowBtnClicked}
          setItemToAddToBasket={setItemToAddToBasket}
          itemToAddToBasket={itemToAddToBasket}
          handleClearFilterList={handleClearFilterList}
          selectedFilterListWheels={selectedFilterListWheels}
          handleChangeMachineType={handleChangeMachineType}
          handleBtnInfo={handleBtnInfo}
          currentSkuInfo={currentSkuInfo}
          clearBtnClicked={clearBtnClicked}
        />
      </Suspense>
    );
  },
  (prev, next) => {
    return (
      JSON.stringify(prev.priceList) === JSON.stringify(next.priceList) &&
      prev.activeType === next.activeType &&
      prev.parameterList === next.parameterList &&
      prev.selectedFilterListWheels === next.selectedFilterListWheels &&
      prev.currentSkuInfo === next.currentSkuInfo &&
      prev.clearBtnClicked === next.clearBtnClicked &&
      prev.loadingPriceList === next.loadingPriceList &&
      prev.itemToAddToBasket === next.itemToAddToBasket
      
    );
  }
);

MemoizedComponent.displayName = "memoized-wheels-price-list"; //'memoized-usedParts'

function Component() {
  const {
    loadingPriceList,
    priceList,
    setActiveType,
    activeType,
    parameterList,
    handleChangeFilterList,
    handShowBtnClicked,
    setItemToAddToBasket,
    itemToAddToBasket,
    handleClearFilterList,
    selectedFilterListWheels,
    handleChangeMachineType,
    handleBtnInfo,
    currentSkuInfo,
    clearBtnClicked
  } = React.useContext(WheelsContext);
  return (
    <MemoizedComponent
      loadingPriceList={loadingPriceList}
      priceList={priceList}
      setActiveType={setActiveType}
      activeType={activeType}
      parameterList={parameterList}
      handleChangeFilterList={handleChangeFilterList}
      handShowBtnClicked={handShowBtnClicked}
      setItemToAddToBasket={setItemToAddToBasket}
      itemToAddToBasket={itemToAddToBasket}
      handleClearFilterList={handleClearFilterList}
      selectedFilterListWheels={selectedFilterListWheels}
      handleChangeMachineType={handleChangeMachineType}
      handleBtnInfo={handleBtnInfo}
      currentSkuInfo={currentSkuInfo}
      clearBtnClicked={clearBtnClicked}
    />
  );
}

function Wheels() {
  return (
    <WheelsProvider>
      <Component />
    </WheelsProvider>
  );
}

export default Wheels;
