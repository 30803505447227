/* eslint-disable react/destructuring-assignment */
// import React from 'react';
// import { Tooltip } from '@material-ui/core';

const RenderCell = ({ cell }) => {
    if (!cell) {
      return null;
    }
    if (cell.isAggregated) {
      return cell.render('Aggregated');
    }
    if (cell.isPlaceholder) {
      return null;
    }
    return cell?.render('Cell');
  };
  
  export default RenderCell;
  