import React, { Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import { TemplateSettingContext } from "../../context/theme-template-context";
import { useSkuSearch } from "../../context/search-sku-context";
import { SearchSkuContext } from "../../context/search-sku-context";
import { MetaContext } from "../../context/meta-context";

const MemoizedComponent = React.memo(
  ({ useSkuSearch, setChosenSku, inputValue, setInputValue, setResult, setResultPartner, searchHistoryOn, handleGetSearchHistory, searchHistoryLoading, searchHistory }) => {
    const { SkuSearcher } = React.useContext(TemplateSettingContext);
    return (
      <Suspense
        fallback={<Skeleton variant="rectangular" width={150} height={28} />}
      >
        <SkuSearcher
          useSkuSearch={useSkuSearch}
          setChosenSku={setChosenSku}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setResult={setResult}
          setResultPartner={setResultPartner}
          searchHistoryOn={searchHistoryOn}
          handleGetSearchHistory={handleGetSearchHistory}
          searchHistoryLoading={searchHistoryLoading} 
          searchHistory={searchHistory}
        />
      </Suspense>
    );
  }
);
MemoizedComponent.displayName = "memoized-sku-search";

function Component(props) {
  const { inputValue, setInputValue, setResult, setResultPartner} = props;
  const { setChosenSku, handleGetSearchHistory, searchHistoryLoading, searchHistory } = React.useContext(SearchSkuContext);
  const { searchHistoryOn } = React.useContext(MetaContext)

  return (
    <MemoizedComponent
      useSkuSearch={useSkuSearch}
      setChosenSku={setChosenSku}
      inputValue={inputValue}
      setInputValue={setInputValue}
      setResult={setResult}
      setResultPartner={setResultPartner}
      searchHistoryOn={searchHistoryOn}
      handleGetSearchHistory={handleGetSearchHistory}
      searchHistoryLoading={searchHistoryLoading}
      searchHistory={searchHistory}
    />
  );
}

export default Component;
