import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddUser from './add-user';
import EditUser from './edit-user'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    
    return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
    }}
        >
        <CloseIcon />
        </IconButton>
        ) : null}
    </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({openUser, setOpenUser, editDataUser,userType}) {
    
    const handleCloseUser = () => {
        setOpenUser({type: '', value: false});
    };

    return (
    <div>
        <BootstrapDialog
        onClose={handleCloseUser}
        aria-labelledby="customized-dialog-title"
        open={openUser.value}
        >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseUser}>
        {
            openUser.type === 'addUser' ?
            'Новый пользователь'
            :
            'Изменить пользователя'
        }
        </BootstrapDialogTitle>
        <DialogContent dividers>
            {
                openUser.type === 'addUser' ?
                <AddUser setOpenUser={setOpenUser}/>
                :
                <EditUser editDataUser={editDataUser} setOpenUser={setOpenUser} userType={userType}/>
            }
        </DialogContent>
        </BootstrapDialog>
    </div>
    );
}