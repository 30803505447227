import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { PresearchProvider, PresearchContext } from '../../context/presearch-context'

const MemoizedComponent = React.memo(({

    loadingPartnerPriceList, 
    priceList, 
    setItemToAddToBasket,
    loadingCompanyPriceList,
    value,
    setChosenSku
})=>{
    const { Presearch } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={150} height={28}/>}>
            <Presearch 
                loadingPartnerPriceList={loadingPartnerPriceList} 
                priceList={priceList} 
                setItemToAddToBasket={setItemToAddToBasket} 
                loadingCompanyPriceList={loadingCompanyPriceList}
                value={value}
                setChosenSku={setChosenSku}
            />
        </Suspense>
    )
}, 
(prev, next) => {
    return  prev.loadingPartnerPriceList === next.loadingPartnerPriceList
     && JSON.stringify(prev.value) === JSON.stringify(next.value)
        && JSON.stringify(prev.priceList) === JSON.stringify(next.priceList)
        && prev.loadingCompanyPriceList === next.loadingCompanyPriceList
        && prev.setItemToAddToBasket === next.setItemToAddToBasket
    }
)
MemoizedComponent.displayName = 'memoized-price-list'

function Component(props){
    const {inputValue} = props;
    const { 
        loadingPartnerPriceList, 
        priceList, 
        setItemToAddToBasket,
        setInputPresearch,
        presearchLoading,
        setChosenSku,
        result
     } = React.useContext(PresearchContext)

     React.useEffect(()=>{
        setInputPresearch(inputValue)
     },[inputValue])

    return (
        <MemoizedComponent
            loadingPartnerPriceList={loadingPartnerPriceList} 
            priceList={priceList} 
            setItemToAddToBasket={setItemToAddToBasket}
            loadingCompanyPriceList={presearchLoading}
            value={result}
            setChosenSku={setChosenSku}
        />
    )
}

function Presearch(props) {
    const {setChosenSku, inputValue} = props;
    return (
        <PresearchProvider>
            <Component setChosenSku={setChosenSku} inputValue={inputValue}/>
        </PresearchProvider>
    )
}

export default Presearch