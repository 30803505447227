import React from 'react'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
import { AuthContext } from './auth-context'


const AppContext = React.createContext()

function AppProvider(props) {
    const contextAuth = React.useContext(AuthContext)
    const timezone = timezoneDetermine()
    const [cartInfo, setCartInfo] = React.useState({})
    const [cartInfoRefetchCounter, setCartInfoRefetchCounter] = React.useState(0)


    React.useEffect(() => {
        simpleCallBackend(contextAuth, '/data/app_ecomn_get_cart_info', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
            .then(data => {
                setCartInfo(data?.data)
            })
    }, [cartInfoRefetchCounter])



    return (
        <AppContext.Provider
            value={{
                cartInfo,
                setCartInfoRefetchCounter,
            }}
            {...props}
        />
    )
}

export { AppProvider, AppContext }