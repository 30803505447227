import React from 'react';
import useLocalStorageState from 'use-local-storage-state'
import themeOrange from '../themes/orange'
import themeRed from '../themes/red'
import themePurple from '../themes/purple'
import themeBlue from '../themes/blue'
import themeGrey from '../themes/grey'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'

const ThemeSettingContext = React.createContext()

function ThemeSettingProvider(props) {
    const [lastUpdateDate, setLastUpdateDate] = useLocalStorageState('lastUpdateThemeContext', { defaultValue: null })
    const [currentThemeName, setCurrentThemeName] = useLocalStorageState('currentThemeName', { defaultValue: null })
    const [currentTheme, setCurrentTheme] = React.useState(themeBlue)

    React.useEffect(() => {
        const cacheDate = new Date(lastUpdateDate)
        const currentDate = new Date()
      
        // проверяется условие на получение настроек: либо их никогда не получали, либо получали больше одного дня назад
        if (lastUpdateDate == null || currentThemeName == null || ((currentDate-cacheDate)/(3600*24.0*1000))>1.0 ) {
            const timezone = timezoneDetermine()
            simpleCallBackend(null, '/theme', timezone, 'GET').then(data => {
                setCurrentThemeName(data?.theme)
                setLastUpdateDate(new Date())
            })
        }
    }, [])

    React.useEffect(()=>{
        switch (currentThemeName) {
            case 'orange':
                setCurrentTheme(themeOrange)
                break;
            case 'purple':
                setCurrentTheme(themePurple)
                break;
            case 'red':
                setCurrentTheme(themeRed)
                break;
            case 'blue':
                setCurrentTheme(themeBlue)
                break;
            case 'grey':
                setCurrentTheme(themeGrey)
                break;
            default:
                setCurrentTheme(themeBlue)
        }
       
    },[currentThemeName])

  return (
    <ThemeSettingContext.Provider
        value={{
            currentThemeName,
            currentTheme,
        }}
        {...props}
    />
  );
}


const TemplateSettingContext = React.createContext();

function TemplateSettingProvider(props) {
    const [lastUpdateDate, setLastUpdateDate] = useLocalStorageState('lastUpdateDateSettingsTemplateContext', { defaultValue: null })
    const [ currentTemplateName, setCurrentTemplateName ] = useLocalStorageState('currentTemplateName', { defaultValue: null })

    React.useEffect(()=>{
        const cacheDate = new Date(lastUpdateDate)
        const currentDate = new Date()

        // проверяется условие на получение настроек: либо их никогда не получали, либо получали больше одного дня назад
        if (lastUpdateDate == null || currentTemplateName == null || ((currentDate-cacheDate)/(3600*24*1000))>1 ) {
            const timezone = timezoneDetermine()
            simpleCallBackend(null, '/template', timezone, 'GET').then(data => {
                setCurrentTemplateName(data?.template)
                setLastUpdateDate(new Date())
            })
        }
    }, [])

    const PriceList = React.lazy(() => import('../components/price-list/'+currentTemplateName))
    const Presearch= React.lazy(() => import('../components/presearch/'+currentTemplateName))
    const SkuSearcher = React.lazy(() => import('../components/sku-searcher/'+currentTemplateName))
    const Button = React.lazy(() => import('../components/button/'+currentTemplateName))
    const AppBar = React.lazy(() => import('../components/appbar/'+currentTemplateName))
    const EntryButtonIcon = React.lazy(() => import('../components/entry-button-icon/'+currentTemplateName))
    const Cart = React.lazy(() => import('../components/cart/'+currentTemplateName))
    const TopProducts = React.lazy(() => import('../components/top-products/'+currentTemplateName))
    const RegistrationDialog = React.lazy(() => import('../components/registration-dialog/'+currentTemplateName))
    const BlockingOrderDialog = React.lazy(() => import('../components/blocking-order-dialog/'+currentTemplateName))
    const Orders = React.lazy(() => import('../components/orders/'+currentTemplateName))
    const Footer = React.lazy(() => import('../components/footer/ '+currentTemplateName))

    const DebtsApplications = React.lazy(() => import('../applications/debts/'+currentTemplateName))
    const Guarantee = React.lazy(() => import('../applications/guarantee/'+currentTemplateName))
    const Wheels = React.lazy(() => import('../applications/wheels/'+currentTemplateName))
    const Tires = React.lazy(() => import('../applications/tires/'+currentTemplateName))


    return (
        <TemplateSettingContext.Provider
        value={{
            currentTemplateName,
            AppBar, Button, EntryButtonIcon, SkuSearcher, PriceList, Presearch, Cart, TopProducts, RegistrationDialog,
            Orders, DebtsApplications, Footer, BlockingOrderDialog, Guarantee, Wheels, Tires
        }}
        {...props}
        />
    );
}

export { ThemeSettingProvider, ThemeSettingContext, TemplateSettingProvider, TemplateSettingContext }