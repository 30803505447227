import React, { } from 'react';
import Box from '@mui/material/Box';
import { ImageContent } from './imageContent'
import { TextContent } from './textContent';
import { VideoContent } from './videoContent';
import { BannerContent } from './bannerComponent';
import { MainContentContext } from '../../../context/main-content-contex';


export default function Main() {
    const [allContent, setAllContent] = React.useState([])
    const { dataContent } = React.useContext(MainContentContext)

    React.useEffect(() => {
        getContent()
    }, [dataContent])

    const getContent = () => {
        setAllContent(dataContent.map( el => el.type == 'carousel' ? 
            ({content_id: el.content_id, name: el.name, link: el.link, width: el.width, height: el.height, type: el.type, markup_sequence: el.markup_sequence, content: JSON.parse(el.content)}) 
            : ({content_id: el.content_id, name: el.name, link: el.link, width: el.width, height: el.height, type: el.type, markup_sequence: el.markup_sequence, content: el.content}) ))
    };

    const enumeration = (el) => {
        switch (el.type) {
            case 'image':
                return <div key={el.id}><ImageContent props={el} /></div>
            case 'video_link':
                return <div key={el.id}><VideoContent props={el} /></div>
            case 'text':
                return <div key={el.id}><TextContent props={el} /></div>
            case 'carousel':
                return <div key={el.id}><BannerContent props={el} /></div>
        }
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box sx={{ maxWidth: 1200, flexGrow: 1 }}>
                {allContent?.map(el => enumeration(el))}
            </Box>
        </div>
    );
}