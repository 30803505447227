import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { MenuIcon } from '../icons';

const useStyles = makeStyles(() => ({
  menuColumnControls: {
    display: 'none',
    height: '100%',
    alignItems: 'center',
    width: '1.5em',
    justifyContent: 'center',
  },
  show: {
    display: 'flex',
  },
  menu: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    padding: '1px',
    backgroundColor: 'white',
  },
}));

const MenuColumnControls = (props) => {
  const {
    column,
    stateHeaderMenu,
    dispatchHeaderMenu,
  } = props;
  const classes = useStyles();

  if (column?.id === 'expander') return null;
  if (!column?.canFilter && !column?.canGroupBy) return null;

  return (
    <div
      className={
        clsx('menuColumnControls', classes.menuColumnControls, {
          [classes.show]: Boolean(stateHeaderMenu?.menuTarget),
        })
      }
      onClick={(e) => dispatchHeaderMenu({ type: 'openMenu', payload: { menuTarget: e.currentTarget, menuTargetColumn: column } })}
    >
      <MenuIcon style={{ width: '0.9em', fontSize: '0.9em' }} />
    </div>
  );
};

export default MenuColumnControls;
