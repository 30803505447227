import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { AppContext } from '../../context/app-context'

function Component(props){
    const { AppBar } = React.useContext(TemplateSettingContext)
    const { cartInfo } = React.useContext(AppContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={5000} height={28} />}>
            <AppBar {...props} rowQuantity={cartInfo?.row_quantity}/>
        </Suspense>
    )
} 

export default Component