import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import { ErrorBoundary } from 'react-error-boundary'
import { AuthProvider } from './context/auth-context'
import { MetaProvider } from './context/meta-context'
import { AppProvider } from './context/app-context'
import { ApplicationsProvider } from './applications/context/applications-context'
import App from './App'
import { ThemeSettingProvider, TemplateSettingProvider } from './context/theme-template-context'
import { UserProvider } from './context/user-context'
import { SnackbarProvider } from 'notistack'
import { MainContentProvider } from './context/main-content-contex'

const sendReportToBackend = (error, componentStack) => {
  console.log('error=', error)
  console.log('componentStack=', componentStack)
  fetch('/report-error', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    body: JSON.stringify({ error: error.message, stack: componentStack }),
    json: true,
  })
}

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Что-то пошло не так... Пожалуйста, обновите страницу</p>
      <pre>{error.message}</pre>
    </div>
  )
}

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={sendReportToBackend}>
    <BrowserRouter>
      <SnackbarProvider>
        <TemplateSettingProvider>
          <ThemeSettingProvider>
            <MetaProvider>
              <AuthProvider>
                <UserProvider>
                  <AppProvider>
                    <ApplicationsProvider>
                      <MainContentProvider>
                        <App />
                      </MainContentProvider>
                    </ApplicationsProvider>
                  </AppProvider>
                </UserProvider>
              </AuthProvider>
            </MetaProvider>
          </ThemeSettingProvider>
        </TemplateSettingProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root')
)