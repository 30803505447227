import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, FormControl, InputLabel, MenuItem, Select ,Checkbox} from '@mui/material';
import { useSnackbar } from 'notistack'
import { makeStyles } from '@mui/styles'
import { UserContext } from '../../../context/user-context';
import { blue } from '@mui/material/colors';
import './style.css'



const useStyles = makeStyles(() => ({
    newPassword: {
        border: 'none',
        color: blue[300],
        background: '#00000000',
        cursor: 'pointer',
        textAlign: 'start',
        fontSize: '13px',
        '&:hover': {
            transition: 'all .3s',
            color: blue[500],
        }
    }
}))

export default function EditUser({ editDataUser, setOpenUser,userType }) {
    const classes = useStyles()
    const [openEditPassword, setOpenEditPassword] = useState(false)
    const {updateUser, userInfo, rightsСheck, supervisorMode } = useContext(UserContext)
    const [form, setForm] = useState({})
    const { enqueueSnackbar } = useSnackbar()

    const handleChange = e => {
        setForm((prev) => ({ ...prev, [e.target.name]: e.target.name ==='order_on' ? e.target.checked : e.target.value }));
    }

    const changePasswordHandler = () => {
        if (openEditPassword) {
            setOpenEditPassword(false)
            setForm({ ...form, password: '' })
        } else {
            setOpenEditPassword(true)
        }

    }

    const handleSubmit = e => {
        e.preventDefault()
        const validForm = {}
        const errors = []
        for (const [key, value] of Object.entries(form)) {
            switch (key) {
                case 'email':
                    /\S+@\S+\.\S+/.test(value.trim()) ?
                        validForm[key] = value.trim()
                        : errors.push(' Введен некорректный email')
                    break;
                case 'first_name':
                    value.trim() ?
                        validForm[key] = value.trim()
                        : errors.push(' Введите имя')
                    break;
                case 'last_name':
                    value.trim() ?
                        validForm[key] = value.trim()
                        : errors.push(' Введите фамилию')
                    break;
                default:
                    validForm[key] = typeof value !== 'boolean' ? value.trim() :value
            }
        }
        if (errors.length) {
            enqueueSnackbar(errors.join(), {
                variant: 'error',
                autoHideDuration: 5000,
            })
        } else {
            updateUser({ user_id: editDataUser.user_id, ...validForm })
            setOpenUser({ type: '', value: false })
        }
    }

    return (
        <Box
            component="form"
            className="form-input-field"
            sx={{
                '& > :not(style)': { m: 2, width: 520, display: 'flex', flexDirection: 'column', justifyContent: 'center', },
            }}
            noValidate
            onSubmit={handleSubmit}
        >
            {
                (userInfo.user_id === editDataUser.user_id || supervisorMode()) &&
                <div  className="form-input-field-blocks">
                    <TextField
                        id="outlined-name"
                        classes="form-input-field"
                        label="Почта*"
                        name='email'
                        value={form.email}
                        defaultValue={editDataUser.email || ''}
                        onChange={handleChange}
                        autoComplete={'new-password'}
                    />
                    <div>
                        <div className={classes.newPassword} onClick={changePasswordHandler}>Новый пароль</div>
                        {
                            openEditPassword &&
                            <TextField
                                id="outlined-name"
                                name='password'
                                label="Пароль"
                                value={form.password || ''}
                                onChange={handleChange}
                                type="password"
                            />
                        }
                    </div>
                    <TextField
                        id="outlined-name"
                        name='first_name'
                        label="Имя*"
                        value={form.first_name}
                        defaultValue={editDataUser.first_name || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="outlined-name"
                        name='last_name'
                        label="Фамилия*"
                        value={form.last_name}
                        defaultValue={editDataUser.last_name || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="outlined-name"
                        name='middle_name'
                        label="Отчество"
                        value={form.middle_name}
                        defaultValue={editDataUser.middle_name || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        id="outlined-name"
                        name='phone_number'
                        label="Телефон"
                        value={form.phone_number}
                        defaultValue={editDataUser.phone_number || ''}
                        onChange={handleChange}
                    />
                        <div style={{ justifyContent: 'space-between', alignItems: 'center',display: userType.match(/admin.*/)?'flex' :'none'}}>
                            <div>
                                {'Оформление заказов'}
                            </div>
                            <Checkbox
                                id="outlined-name"
                                name="order_on"
                                labal="Оформление заказов"
                                style={{ marginRight: '20px' }}
                                defaultChecked={editDataUser.order_on}
                                checked={form['order_on']}
                                onChange={handleChange}
                            />
                        </div>
                </div>
            }
            {
                (rightsСheck() || supervisorMode()) &&
                <div className="form-input-field-blocks">
                    <TextField
                        id="outlined-name"
                        name='position_in_company'
                        label="Должность"
                        value={form.position_in_company}
                        defaultValue={editDataUser.position_in_company || ''}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Права доступа</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='role_id'
                            value={form.role_id}
                            defaultValue={editDataUser.role_id || ''}
                            label="Права доступа*"
                            onChange={handleChange}

                        >
                            <MenuItem value={'1'}>Полный доступ (Администратор)</MenuItem>
                            <MenuItem value={'2'}>Контролируемый доступ (Пользователь)</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            }
            <Button onClick={handleSubmit} color="secondary" variant="outlined">Сохранить</Button>
        </Box>
    );
}