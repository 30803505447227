import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Миксины
const borderBottom = (props) => css({
  borderBottom: `1px solid ${props.theme.colors.borderColor};`,
});
const paddingFirstChild = (props) => css({
  '& > div:first-of-type': {
    paddingLeft: props.theme.sizes.paddingFirstLastCell,
  },
});
const paddingLastChild = (props) => css({
  '& > div:last-of-type': {
    paddingRight: props.theme.sizes.paddingFirstLastCell,
  },
});
const tr = (props) => css({
  height: `${props.theme.sizes.rowHeight}px`,
});
const trHead = (props) => css({
  height: `${props.theme.sizes.rowHeaderHeight ? props.theme.sizes.rowHeaderHeight - 1 : props.theme.sizes.rowHeight}px`,
});

const resizer = () => css({
  '& .resizer': {
    display: 'none',
    width: '1em',
    height: '100%',
    alignItems: 'center',
    // transform: 'translateX(50%)',
    zIndex: 100,
    touchAction: 'none',
    cursor: 'col-resize',
    // '& .isResizing': {
    //   background: 'red',
    // },
  },
});

// Компоненты (стилизация)

export const TableContainer = styled.div((props) => {
  return {
    // height: props.theme.sizes.height ?? '100%',
    border: props.theme.variables.outerBorder ? `1px solid ${props.theme.colors.borderColor} ` : 'unset',
  };
});
export const MainTable = styled.div({
  display: 'block',
  borderSpacing: '0',
  height: '100%',
});
export const THead = styled.div((props) => ({
  height: props.theme.sizes.rowHeaderHeight ?? props.theme.sizes.rowHeight,
  overflowY: 'auto',
  overflowX: 'hidden',
  fontSize: `${props.theme.sizes.fontSize}px`,
  fontWeight: props.theme.sizes.fontWeight ?? '700',
  backgroundColor: props.theme.colors.headerBg,
  color: props.theme.colors.headerTextColor,
  userSelect: 'none',
}), borderBottom);
export const HeaderGroup = styled.div(() => ({
  overflowX: 'hidden',
  '&:hover .resizer': {
    display: 'flex',
  },
}), trHead, paddingFirstChild, paddingLastChild, resizer);
export const CellWrapper = styled.div({
  display: 'flex',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  '&:hover .resizer': {
    display: 'flex',
  },
});
export const Cell = styled.div({
  margin: '0 5px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  padding: '3px 0 3px 0'
  // flex: '0 2',
});
export const TBody = styled.div(() => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  // height: `calc(100% - ${props.theme.sizes.rowHeaderHeight ?? props.theme.sizes.rowHeight}px)`,
  padding: 0,
  margin: 0,
}));
export const innerElement = styled.div((props) => {
  return {
    ...(props.theme.variables.hasScroll && { '& > :last-child > div': { borderBottom: '1px solid transparent' } }),
  };
});
export const Row = styled.div((props) => ({
  borderBottom: `1px solid ${props.theme.colors.borderColor};`,
  fontSize: `${props.theme.sizes.fontSize}px`,
  ...(props.theme.colors.hoverRowBackgroud && { '&:hover': { backgroundColor: `${props.theme.colors.hoverRowBackgroud} !important` } }),
}), tr, paddingFirstChild, paddingLastChild);
