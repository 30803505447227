import React from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

function WarehouseMap({ props }) {
  let coord = props?.split(", ");
  let arrCoord = [+coord[0], +coord[1]];

  return (
    <div className="App">
      <YMaps>
        <Map
          defaultState={{
            center: arrCoord,
            zoom: 16,
          }}
          width="450px"
          height="450px"
          modules={["geolocation", "geocode"]}
        >
          <Placemark geometry={arrCoord} />
        </Map>
      </YMaps>
    </div>
  );
}

export default WarehouseMap;
