import { Card, CardMedia } from '@mui/material'
import React from 'react'



export const ImageContent = ({ props }) => {



    return (
        
    <div key={props.id} style={{display:'flex', justifyContent:'center'}}>
        <Card style={{display:'flex', justifyContent:'center', margin:'20px 0 20px 0', width: +props.width, height: +props.height}}>
            <CardMedia
            component="img"
            image={props.content}
            alt={props.name}
            />
        </Card>
    </div>
    )
}
