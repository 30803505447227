import React, { useEffect, useState } from "react";
import { ButtonBase, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PartnersLogo from "../../../applications/partners-logo/default";
const { MetaContext } = require("../../../context/meta-context");

function Logo() {
  const { logo } = React.useContext(MetaContext);
  const { site } = React.useContext(MetaContext);

  const navigate = useNavigate();

  const [yandexMetric, setYandexMetric] = useState("");

  useEffect(() => {
    setYandexMetric(JSON.parse(localStorage.getItem("yandexMetric"))[0]);
  }, []);

  return (
    <Box>
      <ButtonBase
        type="button"
        href={site ? site : ""}
        onClick={() => (site ? {} : navigate({ pathname: "/" }))}
      >
        <img
          style={{ maxHeight: 80, maxWidth: 250 }}
          src={`data:image/png;base64, ${logo}`}
          alt="logo"
        />
      </ButtonBase>
      {yandexMetric == "88105251" && <PartnersLogo />}
    </Box>
  );
}

export default Logo;
