
import React, { useContext, useState, useEffect, createContext } from 'react'
import simpleCallBackend from '../../utils/api-client'
import timezoneDetermine from '../../utils/timezone-determine'
import { AuthContext } from '../../context/auth-context'
import { ApplicationsContext } from '../context/applications-context'
import { useSnackbar } from 'notistack'


const GuaranteeContext = createContext()

function GuaranteeProvider(props) {
    const { enqueueSnackbar } = useSnackbar()
    const contextAuth = useContext(AuthContext)
    const { clientInfo } = useContext(ApplicationsContext)
    const timezone = timezoneDetermine()
    const [usedParts, setUsedParts] = useState([])

    const [productSaleList, setProductSaleList] = useState([])


    useEffect(() => {
        getUsedPartsReport()
    }, [contextAuth.tokenUnauthorizedUser, contextAuth.tokenAuthorizedUser,])


    const getUsedPartsReport = async (data) => {
        if (contextAuth.tokenAuthorizedUser && clientInfo[0]?.guarantee) {
            const status = data?.status ? data?.status : null
            const result = await simpleCallBackend(contextAuth, '/data/app_ecomn_get_used_parts_reports', timezone, 'POST', { status, do_not_delete_user_id_from_arg: true })
            if (result.is_ok) {
                setUsedParts(result.data)
            }
        }

    }


    const getProductSaleList = async () => {
        if (contextAuth.tokenAuthorizedUser && clientInfo[0]?.guarantee) {
            const result = await simpleCallBackend(contextAuth, '/data/app_ecomn_get_product_sale', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
            if (result.is_ok) {
                setProductSaleList(result.data)
            }
        }
    }


    const setUsedPartReport = async (data) => {
        console.log(clientInfo[0],data,enqueueSnackbar)
        if (contextAuth.tokenAuthorizedUser && clientInfo[0]?.guarantee) {    
            if (data.length && clientInfo.length) {
                const productSale = {
                    contractor_ref_key: clientInfo[0].contractor_ref_key,
                    organization_ref_key: clientInfo[0].organization_ref_key,
                    partner_ref_key: clientInfo[0].partner_ref_key,
                    division_ref_key: data[0]?.division_ref_key,
                    comment: clientInfo[0].contractor,
                    items: data[0].data
                }
                const result = await simpleCallBackend(contextAuth, '/data/app_ecomn_set_used_part', timezone, 'POST', { data: productSale, do_not_delete_user_id_from_arg: true })
                if (result.is_ok) {
                    enqueueSnackbar(result.message, {
                        variant: 'success',
                        autoHideDuration: 3000,
                    })
                    return true  
                } else {
                    enqueueSnackbar(result.message, {
                        variant: 'error',
                        autoHideDuration: 5000,
                    })
                    return false
                }
            }
        }
    }


    return (
        <GuaranteeContext.Provider
            value={{
                usedParts,
                getUsedPartsReport,
                setUsedPartReport,
                getProductSaleList,
                productSaleList,
            }}
            {...props}
        />
    )
}

export { GuaranteeProvider, GuaranteeContext }