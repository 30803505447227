import React from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext} from './auth-context'
import { AppContext} from './app-context'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'
import { useSnackbar } from 'notistack'
import { MetaContext } from './meta-context'

const TopProductsContext = React.createContext()

const TopProductsProvider = (props) => {
  const contextAuth = React.useContext(AuthContext)
  const { topProductsPage } = React.useContext(MetaContext)
  const { setCartInfoRefetchCounter } = React.useContext(AppContext)
  const timezone = timezoneDetermine()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [loadingPriceList, setLoadingPriceList] = React.useState(false)
  const [priceList, setPriceList] = React.useState([])

  const [itemToAddToBasket, setItemToAddToBasket] = React.useState({})
  React.useEffect(()=>{
    if(location?.pathname === '/' && topProductsPage)
    {
      //Запрос прайс-лста собственных товаров
      setLoadingPriceList(true)
      setPriceList([])
      simpleCallBackend(contextAuth, '/data/app_ecomn_get_top_products_price_list', timezone, 'POST', { do_not_delete_user_id_from_arg: true })
      .then(data => {
        if(Array.isArray(data?.data))
          setPriceList(data.data)
        else
          setPriceList([])

        setLoadingPriceList(false)
      })
      .catch(()=>{
        setPriceList([])
        setLoadingPriceList(false)
      })
    }
  },[location])

  //Добавление в корзину
  React.useEffect(()=>{
    if('sku_id' in itemToAddToBasket || 'item_id' in itemToAddToBasket)
    {
        simpleCallBackend(contextAuth, '/data/app_ecomn_add_to_cart', timezone, 'POST', {
            action: 'ADD',
            cart_id: null,
            is_partner_sku: itemToAddToBasket?.is_partner_sku,
            sku_id: itemToAddToBasket?.sku_id,
            item_id: itemToAddToBasket?.item_id,
            partner_search_log_id: itemToAddToBasket?.partner_search_log_id,
            quantity: itemToAddToBasket?.quantity,
            warehouse_id: itemToAddToBasket?.warehouse_id,
            do_not_delete_user_id_from_arg: true, 
        })
        .then(data => {
            setCartInfoRefetchCounter(counter => counter + 1)
            if(data?.is_ok)
                enqueueSnackbar('Товар '+itemToAddToBasket?.pin+' '+itemToAddToBasket?.brand+' в количестве '+itemToAddToBasket?.quantity+' шт успешно добавлен в корзину', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            else
                enqueueSnackbar('Возникла ошибка при добавлении товара в корзину: '+data?.message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
        })

    }
  },[itemToAddToBasket])

  return (
    <TopProductsContext.Provider
      value={{
        loadingPriceList, priceList, setItemToAddToBasket
      }}
      {...props}
    />
  )
}

export { TopProductsProvider, TopProductsContext }

