import React, {
    useRef,
    forwardRef,
    useEffect,
    useMemo,
    useCallback,
    memo,
  } from 'react';
  import {
    useTable,
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    useExpanded,
    useGroupBy,
    useFilters,
    useGlobalFilter,
  } from 'react-table';
  import {
    CircularProgress, Box, Checkbox,
  } from '@mui/material';
  import { useTheme } from '@mui/styles';
  import { VariableSizeList } from 'react-window';
  import { ThemeProvider } from '@emotion/react';
  import {
    TableContainer,
    MainTable,
    THead,
    TBody,
    innerElement,
  } from './table/styled-elements';
  import RenderRow from './table/render-row';
  import useWidth from '../hooks/use-width';
  import HeaderGroup from './table/header-group';
  import ExpanderColumn from './table/expander-column';
  import ExpanderHeader from './table/expander-header';
  import { getDefaultStyles } from './table/utils';
  
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;
  
      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      return (
        <Checkbox
          indeterminate={indeterminate}
          disableRipple
          name="checkedB"
          color="primary"
          ref={resolvedRef}
          {...rest}
          style={{ padding: '0px' }}
        />
      );
      // Todo сделать обычный чекбокс в стиле MUI, чтоб избежать ререндеров
      // return (<input type="checkbox" ref={resolvedRef} {...rest} title style={{ margin: '0px' }} />);
    },
  );
  IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'
  
  // Основная ф-ция таблицы
  const Table = (props) => {
    const muiTheme = useTheme();
    const defaultPropGetter = () => ({});
    const {
      // данные
      data,
      columns,
      // стили и опции таблицы
      styles: inputStyles,
      enableSorting = true,
      disableMultiSort = false,
      disableAbsoluteSortArrows = false,
      enableSelection = false,
      disableHeaderCheckbox = false,
      enableExpanding = false,
      enableGroupBy = false,
      manualGroupBy = false,
      tableName='none',
      // setGroupBy: inputSetGroupBy = () => { },
      // groupBy: inputGroupBy = [],
      enableResizeColumns = false,
      disableHeaderGroupedIcons = false,
      isAllRowsExpandedProp = false,
      enableMenuColumnControls = false,
      isLoading = false,
      // renderLoadingSpinner,
      secondLoading = false,
      autoResetGlobalFilter = true,
      // функции опций
      setSelectedRows = () => { },
      renderRowSubComponent = () => null,
      // перезапись пропсов
      getHeaderProps = defaultPropGetter,
      getColumnProps = defaultPropGetter,
      getRowProps = defaultPropGetter,
      getCellProps = defaultPropGetter,
      initialState: inputInitialState = {
        // sortBy: ,
      },
      skipPageReset,
      updateData,
    } = props;
  
    const headerRef = useRef(null);
    // Измеряем ширину таблицы, (для виртуального списка)
    // Измеряем внутреннюю ширину таблицы (есть ли скролл, чтоб подобрать размер шапки таблицы)
    const tableRef = useRef(null);
    const innerRef = useRef(null);
    const tableWidthArg = inputStyles?.width
    const [tableBodyWidth] = useWidth(tableRef, 'bounding', [data]);
    const [tableInnerWidth] = useWidth(innerRef, 'clientWidth', [data]);

    const tableBodyWidthFinally = tableWidthArg ? tableWidthArg : tableBodyWidth
  
    const diff = tableBodyWidthFinally - tableInnerWidth < 0 ? 0 : tableBodyWidthFinally - tableInnerWidth;

    let nodatalabel = ''
    switch(tableName)
    {
        case "Запрос":
          nodatalabel = 'По данному запросу ничего не найдено'
          break;
        case "Диски":
          nodatalabel = 'По данному запросу ничего не найдено'
          break;
        case "Шины":
          nodatalabel = 'По данному запросу ничего не найдено'
          break;
        case "Блокировка_заказа":
          nodatalabel = 'Вы можете вернуться к оформлению заказа'
          break;
        case "Корзина":
          nodatalabel = 'Корзина пуста'

       break;
        default :
        nodatalabel='Нет данных'
        break;
    }
      

  
    const defaultStyles = useCallback(getDefaultStyles(inputStyles, muiTheme), [inputStyles, muiTheme]);
    const styles = useMemo(() => ({ ...defaultStyles, ...inputStyles }), [inputStyles]);
    const defaultTheme = useMemo(() => ({
      variables: {
        outerBorder: styles.outerBorder,
        muiThemeType: muiTheme.palette.type,
        hasScroll: diff > 0,
        scrollDiff: diff,
      },
      colors: {
        headerBg: muiTheme.palette.type === 'dark' ? styles.headerBgDark : styles.headerBgLight,
        borderColor: styles.borderColor,
        headerBgDark: styles.headerBgDark,
        headerBgLight: styles.headerBgLight,
        headerTextColor: styles?.headerTextColor,
        hoverRowBackgroud: styles?.hoverRowBackgroud,
      },
      sizes: {
        height: styles.height,
        rowHeight: styles.rowHeight,
        rowHeaderHeight: styles.rowHeaderHeight,
        fontSize: styles.fontSize,
        fontWeight: styles.fontWeight,
        paddingFirstLastCell: styles.paddingFirstLastCell,
        rowExpandedHeight: styles.rowExpandedHeight,
      },
    }), []);
    // Вспомогательные функции
    //
    // Стили ячейчки на основе данных из колонки
    const getCellStyles = useCallback((columnOpts, inputRowStyles) => {
      let cellStyles = { ...inputRowStyles };
      if (columnOpts.disableEllipsis) {
        cellStyles = { ...cellStyles, textOverflow: 'unset' };
      }
      return cellStyles;
    }, []);
    // Стили обёртки ячейки
    const getCellWrapperStyles = useCallback((columnOpts, inputCellWrapperStyles) => {
      const style = { ...inputCellWrapperStyles };
      if (columnOpts.align === 'right') {
        return { ...style, justifyContent: 'flex-end' };
      }
      if (columnOpts.align === 'left') {
        return { ...style, justifyContent: 'flex-start' };
      }
      if (columnOpts.align === 'center') {
        return { ...style, justifyContent: 'center' };
      }
      return style;
    }, []);
  
    const defaultColumn = useMemo(
      () => ({
        width: 1,
        expanded: false,
        canGroupBy: false,
        minWidth: 1,
        // maxWidth: 35,
        // maxWidth: `${tableInnerWidth}px`,
        // minWidth: '40px',
        filter: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return !filterValue.includes(rowValue);
          });
        },
      }),
      [],
    );
  
    const listRef = useRef(null);
  
    const onExpand = useCallback(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [])
  
    // Дополнительные колонки
    const expenderColumn = {
      Header: ({ state, isAllRowsExpanded, toggleAllRowsExpanded }) => {
        return (
          <ExpanderHeader
            groupBy={state.groupBy}
            isAllRowsExpanded={isAllRowsExpanded}
            toggleAllRowsExpanded={toggleAllRowsExpanded}
            onExpand={onExpand}
          />
        );
      },
      id: 'expander',
      Cell: ({ row }) => {
        if (row?.canExpand === false) return null;
        return <ExpanderColumn onExpand={onExpand} row={row} />;
      },
      SubCell: () => null, // No expander on an expanded row
      width: 1,
      style: {
        margin: '0',
        maxWidth: '40px',
        minWidth: '40px',
      },
    };
    const selectionColumn = {
      id: 'selection',
      ...(!disableHeaderCheckbox && {
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div
            style={{
              height: '100%',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
      }),
      Cell: ({ row }) => (
        <div
          style={{
            height: '100%',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
      width: 1,
      style: {
        maxWidth: '35px',
        minWidth: '35px',
      },
    };
  
    const table = useTable(
      {
        columns,
        data,
        defaultColumn,
        disableMultiSort,
        manualGroupBy,
        initialState: {
          groupBy: inputInitialState?.groupBy ?? [],
          sortBy: inputInitialState?.sortBy ?? [],
        },
        autoResetExpanded: false,
        autoResetPage: !skipPageReset,
        autoResetGroupBy: !skipPageReset,
        autoResetSelectedRows: !skipPageReset,
        autoResetSortBy: !skipPageReset,
        autoResetFilters: !skipPageReset,
        autoResetRowState: false,
        autoResetGlobalFilter,
        updateData,
      },
      useFilters,
      useGlobalFilter,
      useFlexLayout,
      (enableResizeColumns && useResizeColumns),
      (enableGroupBy && useGroupBy),
      (enableSorting && useSortBy),
      ((enableExpanding || enableGroupBy) && useExpanded),
      (enableSelection && useRowSelect),
      (hooks) => {
        hooks.visibleColumns.push((columnsHookProps, { instance }) => {
          if (!instance.state.groupBy?.length) {
            return columnsHookProps;
          }
          return [
            expenderColumn,
            ...columnsHookProps,
          ];
        });
        if (enableSelection) {
          hooks.visibleColumns.push((columnsHookProps) => [
            selectionColumn,
            ...columnsHookProps,
          ]);
        }
      },
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      toggleAllRowsExpanded = () => { },
      totalColumnsWidth,
      setAllFilters,
      state: { filters },
      preFilteredRows,
    } = table;
  
    useEffect(() => {
      setSelectedRows(selectedFlatRows);
    }, [selectedFlatRows]);

    useEffect(() => {
      // Вероятно баг библиотеки, не нашёл как это нормально починить
      setTimeout(() => {
        toggleAllRowsExpanded(true);
        if (listRef?.current) {
          listRef.current.resetAfterIndex(0);
        }
      }, 0);
    }, [inputInitialState?.groupBy]);
  
    // Стартовое переключение если группированые списки открыты или закрыты параметр задан в (isAllRowsExpandedProp)
    useEffect(() => {
      if (!isLoading) {
        toggleAllRowsExpanded(isAllRowsExpandedProp);
        listRef.current.resetAfterIndex(0);
      }
    }, [data]);
    const renderTableLoading = () => {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%' }}
          minHeight={`${styles.height}px`}
        >
          <CircularProgress />
        </Box>
      );
    };
    const renderTableRows = () => {
      return (
        <VariableSizeList
          height={styles.height}
          width={tableBodyWidthFinally}
          itemCount={rows.length}
          itemSize={(i) => {
            const currentRow = rows[i];
            if (!currentRow) return 0;
            if (currentRow.isExpanded && currentRow.isGrouped) return styles.rowHeight;
            return styles.rowHeight;
          }}
          innerRef={innerRef}
          ref={listRef}
          innerElementType={innerElement}
          itemData={{
            rows,
            prepareRow,
            getRowProps,
            getColumnProps,
            getCellProps,
            getCellWrapperStyles,
            getCellStyles,
            renderRowSubComponent,
            enableResizeColumns,
            tableInnerWidth,
            totalColumnsWidth,
            diff,
          }}
        >
          {RenderRow}
        </VariableSizeList>
      );
    };
  
    return (
      <ThemeProvider theme={defaultTheme}>
        <TableContainer>
          <MainTable {...getTableProps()} ref={tableRef}>
            <THead style={{ paddingRight: `${diff}px` }}>
              {
                headerGroups.map((headerGroup) => {
                  const key = headerGroup.getHeaderGroupProps();
                  return (
                    <HeaderGroup
                      key={key}
                      headerGroup={headerGroup}
                      headerRef={headerRef}
                      getColumnProps={getColumnProps}
                      getHeaderProps={getHeaderProps}
                      getCellWrapperStyles={getCellWrapperStyles}
                      disableAbsoluteSortArrows={disableAbsoluteSortArrows}
                      listRef={listRef}
                      enableMenuColumnControls={enableMenuColumnControls}
                      disableHeaderGroupedIcons={disableHeaderGroupedIcons}
                      enableResizeColumns={enableResizeColumns}
                      data={data}
                      filters={filters}
                      preFilteredRows={preFilteredRows}
                      setAllFilters={setAllFilters}
                    />
                  );
                })
              }
            </THead> 
            <TBody
              {...getTableBodyProps()}
              onScroll={(e) => {
                headerRef.current.scrollLeft = e.target.scrollLeft;
              }}
            >
              <div style={{ display: rows.length === 0 ? "none" : "flex" }}>
                {renderTableRows()}
              </div>
              <div
                style={{
                  display:
                    rows.length === 0 && secondLoading === false && isLoading === false
                      ? "flex"
                      : "none",
                  width: "100%",
                  height: styles.height,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {nodatalabel}
              </div>
              <div
                style={{
                  display:
                    rows.length === 0 && secondLoading === false && isLoading === true
                      ? "flex"
                      : "none",
                  width: "100%",
                  height: styles.height,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {renderTableLoading()}
              </div>
            </TBody>           
          </MainTable>
        </TableContainer>
      </ThemeProvider>
    );
  };
  
  export default memo(Table);
  