const simpleCallBackend = async (context, endpoint, timezone, method, body = null) => {
  try
  {
    let options = {
      method,
      timezone,
      headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept': 'application/json',
      ...((context?.tokenAuthorizedUser)?{'Authorization':'Bearer '+ context?.tokenAuthorizedUser}:{}),
      ...((context?.tokenUnauthorizedUser)?{'IAuthorization':'Bearer '+ context?.tokenUnauthorizedUser}:{})
      },
    }
  
    if (options.method ==='POST') options.body = JSON.stringify(body)
    const response = await fetch(endpoint, options)

    if (response.status === 401)
    {
      if(context != null)
      {
        context.setUnauthorizedUserIdentity(null)
        context.setTokenAuthorizedUser(null)
      }
    }

    const result = response.json()
    return result
  }
  catch(error)
  {
    fetch('/report-error', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify({ error: error.toString(), stack:  `ошибка при выполнении запроса \nurl: ${endpoint} \nbody: ${JSON.stringify(body)}`}),
      json: true,
    })
    return {}
  }
}

export default simpleCallBackend
