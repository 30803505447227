import React from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { AuthContext } from './auth-context'
import { MetaContext } from './meta-context'
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'


const useSkuSearch = (searchString, setLoading, shouldStartSearchSku, setShouldStartSearchSku, setLoadingPartner) => {
  const contextAuth = React.useContext(AuthContext)
  const metaContext = React.useContext(MetaContext)
  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    if (shouldStartSearchSku) {
      if (searchString.length >= 3) {
        setLoading(true)
        const timezone = timezoneDetermine()
        simpleCallBackend(contextAuth, '/api_fast_search_sku', timezone, 'POST', { article: searchString, limit_results: metaContext.limitTooltipForSearch })
          .then(data => {
            if (Array.isArray(data?.data)) {
              setOptions(data.data)
            } else {
              setOptions([])
            }
            if (data.is_partner && metaContext.checkPartnerQWEP) {
              setLoadingPartner(true)
              simpleCallBackend(
                contextAuth,
                "/fast_search_sku_partner",
                timezone,
                "POST",
                {
                  // last_result: data.data,
                  article: searchString,
                  limit: data.count_partner_sku,
                  cross_flag: metaContext.crossSearchCheckQWEP,
                }
              ).then((res) => {
                if (res.is_ok) {
                  let tmp = res.data;

                  data.data.forEach((el) =>
                    tmp
                      .filter((item) => item.pin === el.pin)
                      .forEach((item) => {
                        item.sku_id = el.sku_id;
                        item.isPartner = true;
                      })
                  );
                  setOptions(
                    JSON.parse(JSON.stringify([...data.data, ...tmp]))
                  );
                }
              });
                setLoadingPartner(false)
            }
            setLoading(false)
          })
          .catch(() => {
            setOptions([])
            setLoading(false)
          })
      }
      else {
        setOptions([])
      }
      setShouldStartSearchSku(false)
    }
  }, [searchString])

  return options
}

const SearchSkuContext = React.createContext()
const SearchSkuProvider = (props) => {
  const navigate = useNavigate()
  const [chosenSku, setChosenSku] = React.useState({})
  const [inputValue, setInputValue] = React.useState('')
  const [searchHistoryLoading,setSearchHistoryLoading] = React.useState(false)
  const [searchHistory,setSearchHistory] = React.useState([])

  const contextAuth = React.useContext(AuthContext)
  const timezone = timezoneDetermine()

  React.useEffect(() => {
    if (typeof chosenSku === 'object' && chosenSku !== null) {
      if ('pin' in chosenSku) {
        navigate({
          pathname: '/search',
          search: `?${createSearchParams({
            sku_id: chosenSku.sku_id ?? 'partnership',
            pin: chosenSku.pin,
            brand: chosenSku.brand,
            description: chosenSku.description,
          })}`,
        })
      }
    }
  }, [chosenSku])

  const handleGetSearchHistory = (searchString) =>{
    setSearchHistoryLoading(true)       
    simpleCallBackend(contextAuth, '/data/app_ecomn_get_search_history', timezone, 'POST', { article: searchString, limit_results: '30', do_not_delete_user_id_from_arg: true })
    .then(res => {
      if (res?.data)
        setSearchHistory(JSON.parse(JSON.stringify(res?.data)));
    })
    .catch(() => {
      setSearchHistory([])
    })
    .finally(()=>{
      setSearchHistoryLoading(false)
    })
  }

  return (
    <SearchSkuContext.Provider
      value={{
        setChosenSku,
        inputValue,
        setInputValue,
        searchHistoryLoading,
        searchHistory,
        handleGetSearchHistory
      }}
      {...props}
    />
  )
}

export { SearchSkuProvider, SearchSkuContext, useSkuSearch }

