import React, { Suspense } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { TemplateSettingContext } from '../../context/theme-template-context'
import { GuaranteeProvider, GuaranteeContext } from './guarantee-context'

            

const MemoizedComponent = React.memo(({
    usedParts, 
    getUsedPartsReport,
    setUsedPartReport,
    getProductSaleList,
    productSaleList,
}) => {
    const { Guarantee } = React.useContext(TemplateSettingContext)
    return (
        <Suspense fallback={<Skeleton variant="rectangular" width={1200} height={500} />}>
            <Guarantee
                usedParts={usedParts} getUsedPartsReport={getUsedPartsReport} setUsedPartReport={setUsedPartReport} productSaleList={productSaleList} getProductSaleList={getProductSaleList}
            />
        </Suspense>
    )
},
    (prev, next) => {
        return JSON.stringify(prev.usedParts) === JSON.stringify(next.usedParts)
        && prev.getUsedPartsReport === next.getUsedPartsReport
    }
    
)

MemoizedComponent.displayName = 'memoized-usedParts'


function Component() {
    const { 
        usedParts, 
        getUsedPartsReport,
        setUsedPartReport,
        productSaleList,
        getProductSaleList,
        } = React.useContext(GuaranteeContext)
    return (
        <MemoizedComponent
            usedParts={usedParts} getUsedPartsReport={getUsedPartsReport} setUsedPartReport={setUsedPartReport} productSaleList={productSaleList} getProductSaleList={getProductSaleList}
        />
    )
}


function Guarantee() {

    return (
        <GuaranteeProvider>
            <Component />
        </GuaranteeProvider>
    )
}

export default Guarantee