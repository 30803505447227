import { Box } from "@mui/material";
import React from "react";
import Navbar from "./navbar";
import { ContactsContext } from "../contact-context";

const Contacts = () => {
  const contactsContext = React.useContext(ContactsContext)
  return (
    <Box style={{ marginTop: "10px", width: "100%" }}>
      <Navbar contacts={contactsContext.companyContactList} />
    </Box>
  );
};


export default Contacts;
