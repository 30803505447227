import React from "react";
import Contacts from "./default/index";
import { ContactsProvider } from "./contact-context";

const Component = (props) => {
  return (
    <ContactsProvider>
      <Contacts {...props} />
    </ContactsProvider>
  );
};

export default Component;
