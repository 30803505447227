import React from 'react';
import {
  UnExpandIcon,
  ExpandIcon,
} from '../icons';

const ExpanderColumn = ({ onExpand, row }) => {
  return (
    <div
      style={{
        flex: 1,
        alignItems: 'center',
        display: 'flex',
      }}
      onClick={(e) => {
        e.stopPropagation();
        onExpand();
        row.toggleRowExpanded();
      }}
    >
      {row.isExpanded ? <UnExpandIcon /> : <ExpandIcon />}
    </div>
  );
};

export default ExpanderColumn;
