import React from 'react'
import useLocalStorageState from "use-local-storage-state"
import simpleCallBackend from '../utils/api-client'
import timezoneDetermine from '../utils/timezone-determine'

const MetaContext = React.createContext()

function MetaProvider(props) {
  const [logo, setLogo] = useLocalStorageState('logo', { defaultValue: null })
  const [hotline, setHotline] = useLocalStorageState('hotline', { defaultValue: null })
  const [informationCompany, setInformationCompany] = useLocalStorageState('information_company', { defaultValue: null })
  const [titleInformationCompany, setTitleInformationCompany] = useLocalStorageState('title_information_company', { defaultValue: null })
  const [site, setSite] = useLocalStorageState('site', { defaultValue: null })
  const [lastSiteUpdateDate, setLastSiteUpdateDate] = useLocalStorageState('lastSiteUpdate', { defaultValue: null })
  const [favicon, setFavicon] = useLocalStorageState('favicon', { defaultValue: null })
  const [yandexMetric, setYandexMetric] = useLocalStorageState('yandexMetric', { defaultValue: [87968533] })
  const [lastLogoUpdateDate, setLastLogoUpdateDate] = useLocalStorageState('lastLogoUpdate', { defaultValue: null })
  const [lastHotlineUpdateDate, setLastHotlineUpdateDate] = useLocalStorageState('lastHotlineUpdate', { defaultValue: null })
  const [lastInformationUpdateDate, setLastInformationUpdateDate] = useLocalStorageState('lastInformationCompanyUpdate', { defaultValue: null })
  const [lastTitleInformationUpdateDate, setLastTitleInformationUpdateDate] = useLocalStorageState('lastTitleInformationCompanyUpdate', { defaultValue: null })
  const [lastFaviconUpdateDate, setLastFaviconUpdateDate] = useLocalStorageState('lastFaviconUpdate', { defaultValue: null })
  const [lastYandexMetricUpdateDate, setLastYandexMetricUpdateDate] = useLocalStorageState('lastYandexMetricUpdate', { defaultValue: null })
  const [lastCheckPartnerQWEP, setLastCheckPartnerQWEP] = useLocalStorageState('lastCheckPartnerQWEPUpdate', { defaultValue: null })
  const [checkPartnerQWEP, setCheckPartnerQWEP] = useLocalStorageState('checkPartnerQWEP', { defaultValue: null })
  const [lastLimitTooltipForSearch, setLastLimitTooltipForSearch] = useLocalStorageState('lastLimitTooltipForSearch', { defaultValue: null })
  const [limitTooltipForSearch, setLimitTooltipForSearch] = useLocalStorageState('limitTooltipForSearch', { defaultValue: null })
  const [lastCrossSearchCheckQWEP, setLastCrossSearchCheckQWEP] = useLocalStorageState('lastCrossSearchCheckQWEP', { defaultValue: null })
  const [crossSearchCheckQWEP, setCrossSearchCheckQWEP] = useLocalStorageState('crossSearchCheckQWEP', { defaultValue: null })
  const [lastPartnerCheckInStock, setLastPartnerCheckInStock] = useLocalStorageState('lastPartnerCheckInStock', { defaultValue: null })
  const [partnerCheckInStock, setPartnerCheckInStock] = useLocalStorageState('crossSearchCheckQWEP', { defaultValue: null })
  const [lastTopProductsPage, setLastTopProductsPage] = useLocalStorageState('lastTopProductsPage', { defaultValue: null })
  const [topProductsPage, setTopProductsPage] = useLocalStorageState('topProductsPage', { defaultValue: null })
  const [lastTiresPageUpdateDate, setLastTiresPageUpdateDate] = useLocalStorageState('lastTiresPageUpdateDate', { defaultValue: null });
  const [tiresPage, setTiresPage] = useLocalStorageState('tiresPage', { defaultValue: null })
  const [lastWheelsPageUpdateDate, setLastWheelsPageUpdateDate] = useLocalStorageState('lastWheelsPageUpdateDate', { defaultValue: null });
  const [lastContactsPageUpdateDate, setLastContactsPageUpdateDate] = useLocalStorageState('lastContactsPageUpdateDate', { defaultValue: null });
  const [wheelsPage, setWheelsPage] = useLocalStorageState('wheelsPage', { defaultValue: null })
  const [contactsPage, setContactsPage] = useLocalStorageState('contactsPage', { defaultValue: null })
  const [lastMainPage, setLastMainPage] = useLocalStorageState('lastMainPage', { defaultValue: null })
  const [topMainPage, setTopMainPage] = useLocalStorageState('topMainPage', { defaultValue: null })
  const [lastStartPage, setLastStartPage] = useLocalStorageState('lastStartPage', { defaultValue: null })
  const [startPage, setStartPage] = useLocalStorageState('startPage', { defaultValue: null })
  const [storeUnavailable, setStoreUnavailable] = React.useState(false)
  const [checkPriceCart, setCheckPriceCart] = React.useState(false)
  const [useQwepCrossForCompany, setUseQwepCrossForCompany] = React.useState(null)
  const [useQwepCrossForCompanyLoading, setUseQwepCrossForCompanyLoading] = React.useState(false)
  const [lastSearchHistoryOnUpdate, setLastSearchHistoryOnUpdate,] = useLocalStorageState('lastSearchHistoryOn', { defaultValue: null })
  const [searchHistoryOn, setSearchHistoryOn] = useLocalStorageState('searchHistoryOn', { defaultValue: false })
  const [partOrderOn, setPartOrderOn] = useLocalStorageState('partOrderOn', { defaultValue: false })
 

  const setFaviconOnPage = () => {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = favicon
  }

  React.useEffect(() => {
    // проверяется условие на получение настроек: либо их никогда не получали, либо получали больше одного дня назад
    let lastUpdate = new Date(lastLogoUpdateDate)
    const currentDate = new Date()
    if (lastLogoUpdateDate === null || logo === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/logo', timezone, 'GET').then(data => {
        setLogo(data.logo_base64)
        setLastLogoUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastStartPage)
    if (lastStartPage === null || startPage === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/page/start_page', timezone, 'GET').then(data => {
        setStartPage(data.start_page)
        setLastStartPage(new Date())
      })
    }


    lastUpdate = new Date(lastTopProductsPage)
    if (lastTopProductsPage === null || topProductsPage === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/page/top_products', timezone, 'GET').then(data => {
        setTopProductsPage(data.top_products_page === 'True' ? true : false)
        setLastTopProductsPage(new Date())
      })
    }

    lastUpdate = new Date(lastTiresPageUpdateDate)
    if (lastTiresPageUpdateDate === null || tiresPage === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/page/tires_page', timezone, 'GET').then(data => {
        setTiresPage(data.tires_page === 'True' ? true : false)
        setLastTiresPageUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastWheelsPageUpdateDate)
    if (lastWheelsPageUpdateDate === null || wheelsPage === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/page/wheels_page', timezone, 'GET').then(data => {
        setWheelsPage(data.wheels_page === 'True' ? true : false)
        setLastWheelsPageUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastContactsPageUpdateDate)
    if (lastContactsPageUpdateDate === null || contactsPage === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/page/contacts_page', timezone, 'GET').then(data => {
        setContactsPage(data.contacts_page === 'True' ? true : false)
        setLastContactsPageUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastMainPage)
    if (lastMainPage === null || topMainPage === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/page/main', timezone, 'GET').then(data => {
        setTopMainPage(data.main_page === 'True' ? true : false)
        setLastMainPage(new Date())
      })
    }

    lastUpdate = new Date(lastPartnerCheckInStock)
    if (lastPartnerCheckInStock === null || partnerCheckInStock === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/partner/partner_check_in_stock', timezone, 'GET').then(data => {
        setPartnerCheckInStock(data.partner_QWEP_check === 'True' ? true : false)
        setLastPartnerCheckInStock(new Date())
      })
    }

    lastUpdate = new Date(lastCheckPartnerQWEP)
    if (lastCheckPartnerQWEP === null || checkPartnerQWEP === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/partner/partner_check_qwep', timezone, 'GET').then(data => {
          setCheckPartnerQWEP(data.partner_QWEP_check === 'True' ? true : false)
          setLastCheckPartnerQWEP(new Date())
      })
    }

    lastUpdate = new Date(lastLimitTooltipForSearch)
    if (lastLimitTooltipForSearch === null || limitTooltipForSearch === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/partner/partner_limit_fast_search', timezone, 'GET').then(data => {
          setLimitTooltipForSearch(data.limit_tooltip_for_search_QWEP ?? 30)
          setLastLimitTooltipForSearch(new Date())
      })
    }

    lastUpdate = new Date(lastLimitTooltipForSearch)
    if (lastCrossSearchCheckQWEP === null || crossSearchCheckQWEP === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/partner/partner_check_cross', timezone, 'GET').then(data => {
          setCrossSearchCheckQWEP(data.cross_search_check_QWEP === 'True' ? true : false)
          setLastCrossSearchCheckQWEP(new Date())
      })
    }

    lastUpdate = new Date(lastHotlineUpdateDate)
    if (lastHotlineUpdateDate === null || hotline === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/hotline', timezone, 'GET').then(data => {
        setHotline(JSON.parse(data.hotline))
        setLastHotlineUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastInformationUpdateDate)
    if (lastInformationUpdateDate === null || informationCompany === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/information_company', timezone, 'GET').then(data => {
        setInformationCompany(data.information_company)
        setLastInformationUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastTitleInformationUpdateDate)
    if (lastTitleInformationUpdateDate === null || titleInformationCompany === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/title_information_company', timezone, 'GET').then(data => {
        setTitleInformationCompany(data.title_information_company)
        setLastTitleInformationUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastSiteUpdateDate)
    if (lastSiteUpdateDate === null || site === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/site', timezone, 'GET').then(data => {
        setSite(data.site)
        setLastSiteUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastFaviconUpdateDate)
    if (lastFaviconUpdateDate === null || favicon  === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/favicon', timezone, 'GET').then(data => {
        setFavicon(data.favicon)
        setLastFaviconUpdateDate(new Date())
      })
    }

    lastUpdate = new Date(lastYandexMetricUpdateDate)
    if (lastYandexMetricUpdateDate === null || yandexMetric === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/yandex_metric', timezone, 'GET').then(data => {
        if (data?.yandex_metric_id.length > 0) {
          setYandexMetric([+data?.yandex_metric_id])
          setLastYandexMetricUpdateDate(new Date())
        }
      })
    }

    lastUpdate = new Date(lastSearchHistoryOnUpdate)
    if (lastSearchHistoryOnUpdate === null || favicon === null || ((currentDate - lastUpdate) / (3600 * 24.0 * 1000)) > 1.0) {
      const timezone = timezoneDetermine()
      simpleCallBackend(null, '/use_search_history', timezone, 'GET').then(data => {
        if (data?.use_search_history) {
          setSearchHistoryOn(data?.use_search_history === 'True' ? true : false)
          setLastSearchHistoryOnUpdate(new Date())
        }
      })
    }
    
    fetch("/use_qwep_cross_for_company", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setUseQwepCrossForCompany(data?.use_qwep_cross_for_company === "True" ? true : false);
      }).
      finally(()=>{
       setUseQwepCrossForCompanyLoading(false)
      })

    fetch("/store_unavailable", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setStoreUnavailable(data?.store_unavailable === "True" ? true : false);
      });

    fetch("/use_check_price_in_cart", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCheckPriceCart(data?.use_check_price_in_cart === "True" ? true : false);
      });

    fetch("/use_part_order", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPartOrderOn(data?.use_part_order === "True" ? true : false);
      });
    
  }, [])
  React.useEffect(() => {
    setFaviconOnPage()
  }, [favicon])


  return (
    <MetaContext.Provider
      value={{
        logo, 
        hotline, 
        informationCompany, 
        titleInformationCompany, 
        site, 
        favicon, 
        yandexMetric,
        checkPartnerQWEP,
        limitTooltipForSearch,
        crossSearchCheckQWEP,
        partnerCheckInStock,
        topProductsPage,
        topMainPage,
        tiresPage,
        wheelsPage,
        contactsPage,
        startPage,
        storeUnavailable,
        useQwepCrossForCompany,
        useQwepCrossForCompanyLoading,
        checkPriceCart,
        searchHistoryOn,
        partOrderOn
      }}
      {...props}
    />
  )
}

export { MetaProvider, MetaContext }