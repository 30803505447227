import React from 'react';

const iconsStyles = {
  fill: 'currentColor',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fontSize: '1.1rem',
  userSelect: 'none',
  // cursor: 'pointer',
};
export const UnExpandIcon = () => (
  <svg focusable="false" viewBox="0 0 20 20" aria-hidden="true" style={iconsStyles}>
    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
  </svg>
);
export const ExpandIcon = () => (
  <svg focusable="false" viewBox="0 0 20 20" aria-hidden="true" style={iconsStyles}>
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
  </svg>
);

export const PhotoCamera = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <circle cx="12" cy="12" r="3.2" />
    <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
  </svg>
);

export const Cart = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);

export const InfoIcon = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
  </svg>
);

export const Edit = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </svg>
);

export const Disable = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
  </svg>
);
export const Remove = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
);
export const ExclamationMark = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <circle cx="12" cy="19" r="2" />
    <path d="M10 3h4v12h-4z" />
  </svg>
);
export const Comment = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" />
  </svg>
);
export const Person = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
  </svg>
);

export const Separator = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);

export const SortIconUp = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M7 14l5-5 5 5z" />
  </svg>
);

export const SortIconDown = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M7 10l5 5 5-5z" />
  </svg>
);

export const CloseIcon = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M 3 5 v 14 c 0 1.1 0.9 2 2 2 h 14 c 1.1 0 2 -0.9 2 -2 V 5 c 0 -1.1 -0.9 -2 -2 -2 H 5 c -1.1 0 -2 0.9 -2 2 Z m 13.3 11.29 c -0.39 0.39 -1.02 0.39 -1.41 0 L 12 13.41 L 9.11 16.3 c -0.39 0.39 -1.02 0.39 -1.41 0 a 0.9959 0.9959 0 0 1 0 -1.41 L 10.59 12 L 7.7 9.11 a 0.9959 0.9959 0 0 1 0 -1.41 c 0.39 -0.39 1.02 -0.39 1.41 0 L 12 10.59 l 2.89 -2.88 c 0.39 -0.39 1.02 -0.39 1.41 0 c 0.39 0.39 0.39 1.02 0 1.41 L 13.41 12 l 2.89 2.88 c 0.38 0.39 0.38 1.03 0 1.41 Z" />
  </svg>
);

export const ArrowIconUp = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
  </svg>
);

export const ArrowIconDown = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
  </svg>
);

export const MenuIcon = (props) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ ...iconsStyles, ...props?.style }}>
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);
